<template>
    <div class="shipmentContainer">
        <div class="containerData">
            <dwlist source="local" :defaultData="conlistData" :showHeader="false" v-loading="conlistLoading" :Teu="teu">
                <el-table-column width="200px" type="index">
                    <template slot-scope="scope">
                        <div class="imagaDiv">
                            <img class="image" src="../../assets/images/container.png"></img>
                            <div class="index">{{ scope.row.index }}</div>
                            <div class="containerType">{{ scope.row.containerType }}</div>
                        </div>
                        <div class="DGCargo" v-show="scope.row.isDg=='DG'">
                            <svg-icon icon-class="HazardousMaterials1"></svg-icon>
                            <div class="text">
                                <div class="type">{{ scope.row.flashPoint }}{{ scope.row.imoClass }}{{scope.row.undgCode}}</div>
                            </div>
                        </div>
                        <div class="RFCargo" v-show="scope.row.isWenDuKongZh=='false'&&scope.row.isFr=='RF'">
                            <svg-icon icon-class="temp"></svg-icon>
                            <div class="text">
                                <div class="type">{{$t('lang.NText131')}}</div>
                                <div class="temp">{{ scope.row.setpointtemp }}～{{ scope.row.setpointtempunit }}℃</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="" :label="$t('lang.BText8')">
                    <template slot-scope="scope">
                        <div class="containerRight">
                            <div class="container-line">
                                <div class="itemNo">
                                    <div class="label">{{$t('lang.NText132')}}</div>
                                    <div class="value">{{ scope.row.containerNo }}</div>
                                </div>
                                <div class="itemNo">
                                    <div class="label">{{$t('lang.NText133')}}</div>
                                    <div class="value">{{ scope.row.sealNo }}</div>
                                </div>
                            </div>

                            <el-collapse accordion @change="collapseChange">
                                <el-collapse-item :name='scope.row.index + "1"'>
                                    <template slot="title">
                                        <div class="MileStone">
                                            <div class="container-line">{{$t('lang.NText134')}}</div>
                                            <div class="container-line">
                                                <div class="MileStone Current">
                                                    <div class="label">{{$t('lang.NText135')}}</div>
                                                    <div class="value" :title="scope.row.currentStatus">{{ scope.row.currentStatus }}</div>
                                                    <div class="date">
                                                        {{ scope.row.currentTime }}
                                                    </div>
                                                </div>
                                                <div class="MileStone Next">
                                                    <div class="label">{{$t('lang.QText19')}}</div>
                                                    <div class="value" :title="scope.row.currentStatus_next">
                                                        {{ scope.row.currentStatus_next }}
                                                    </div>
                                                    <div class="date">
                                                        {{ scope.row.currentTime_next }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="TrackingList">
                                        <!-- <div class="line1" v-if="scope.row.nodeListShow && scope.row.nodeListShow.length > 0"></div> -->
                                        <div class="listData" v-if="scope.row.nodeListShow && scope.row.nodeListShow.length > 0">
                                            <div class="listItem" v-for="(item, index) in scope.row.nodeListShow" :key="index" :class="{
                                              status1: item.state == '1',
                                              status2: item.currState == '2',
                                              status3: item.state == '2',
                                                  }">
                                                <div class="listItemLeft">
                                                  <div class="line2" v-show="index < scope.row.nodeListShow.length - 1"></div>
                                                    <div class="icon">
                                                        <svg-icon icon-class="FCL2" v-show="item.iconindex == '1' && item.isSHow"></svg-icon>
                                                        <svg-icon icon-class="oceanFreight2" v-show="item.iconindex == '2' && item.isSHow"></svg-icon>
                                                        <svg-icon icon-class="train" v-show="item.iconindex == '3' && item.isSHow"></svg-icon>
                                                        <svg-icon icon-class="trucks" v-show="item.iconindex == '4' && item.isSHow"></svg-icon>
                                                    </div>
                                                </div>
                                                <div class="listItemRight">
                                                    <div class="rightTitle">{{ item.eventName }}</div>
                                                    <div class="address">
                                                        {{item.location}}
                                                    </div>
                                                    <div class="time">{{item.eventTime.replace('T', ' ').split(' ')[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="NoData">
                                            <img src="../../assets/images/empty_img.png" alt="">
                                            <p>{{$t('lang.NText136')}}</p>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Cargo details" name="2">
                                    <div>
                                        <ul class="containerUL">
                                            <li>
                                                <span>{{$t('lang.BText48')}}:</span>
                                                <span>{{scope.row.weight}}{{scope.row.weightCode}}</span>
                                            </li>
                                            <li>
                                                <span>{{$t('lang.BText47')}}:</span>
                                                <span>{{scope.row.volume}}{{scope.row.volumeCode}}</span>
                                            </li>
                                            <li>
                                                <span>{{$t('lang.NText137')}}:</span>
                                                <span>{{scope.row.unit}}{{scope.row.unitCode}}</span>
                                            </li>
                                            <li>
                                                <span>{{$t('lang.QText47')}}:</span>
                                                <span>{{scope.row.commodity}}{{scope.row.commodityCode}}</span>
                                            </li>
                                            <li>
                                                <span>{{$t('lang.NText138')}}:</span>
                                                <span>{{scope.row.description}}{{scope.row.descriptionCode}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>

                            <div class="container-line">
                                <div class="itemNo">
                                    <div class="label">{{$t('lang.NText139')}}</div>
                                    <div class="date">{{ scope.row.lastFreeDay }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </dwlist>
        </div>
    </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
export default {
  components: {
    dwlist,
  },
  computed: {
    // shipmentData: function () {
    //   return this.$store.state.api.shipmentData;
    // },
  },
  data() {
    return {
      queryForm: {},
      conlistData: [],
      TrackClickArr: [],
      conlistLoading: false,
      teu: "",
    };
  },
  props : {
    shipmentData: {
      type: Object,
      default: () => {}
    },
  },
  created() {
      this.TrackClickArr = JSON.parse(sessionStorage.getItem('TrackClickArr'));
      this.getlListData()
  },
  methods: {
    collapseChange(e) {
      console.log(e);
    },
    getlListData() {
      this.conlistLoading = true;
      let query = {
        shipmentId: this.shipmentData.shipmentId,
        consoleId: this.shipmentData.consoleId,
      };
      this.$ajax
        .cPost(this.source.shipment_api.conlistData, query)
        .then((res) => {
          if (res.data.result == "true") {
            res.data.list.forEach((el) => {
              let obj;
              this.TrackClickArr.forEach((req) => {
                if (el.containerNo == req.containerNumberL) {
                  obj = req;
                  el.nodeListShow = this.nodeListShowFun(obj.nodeListShow);
                  el.currentStatus = obj.currentStatus;
                  el.currentTime = obj.currentTime;
                  el.currentStatus_next = obj.currentStatus_next;
                  el.currentTime_next = obj.currentTime_next;
                }
              });
            });
            this.conlistData = res.data.list;
            this.teu = res.data.teu + '';
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
          this.conlistLoading = false;
        });
    },
    nodeListShowFun(nodeListShow) {
      let arr = JSON.parse(JSON.stringify(nodeListShow)).reverse();
      let iconindex = "";
      arr.forEach((res, index) => {
        if (index == 0) {
          iconindex = res.iconindex;
          res.isSHow = true;
        } else {
          if (iconindex == res.iconindex) {
            res.isSHow = false;
          } else {
            arr[index - 1].isSHow = true;
            iconindex = res.iconindex;
            res.isSHow = true;
          }
        }
        if (index == arr.length - 1) {
          res.isSHow = true;
        }
      });
      return arr;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.containerUL {
  list-style: none;
  li {
    span {
      &:nth-child(1) {
        color: #999999;
        display: inline-block;
        width: 100px;
        text-align: right;
        font-weight: bold;
        vertical-align: top;
      }
      &:nth-child(2) {
        margin-left: 15px;
        color: #132569;
        font-weight: bold;
        width: 440px;
        display: inline-block;
      }
    }
  }
}
.shipmentContainer {
  .TrackingList {
    background-color: $background1;
    border-top: 4px solid $mainColor;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    .line1 {
      margin-top: 20px;
      margin-left: 45px;
      border-left: 4px dashed $mainColor;
      margin-bottom: 20px;
    }

    .listData {
      // margin-left: -48px;
      width: 100%;
    }

    .listItem {
      display: flex;
      width: 100%;
      .listItemLeft {
        position: relative;
        height: 100px;
        width: 100px;
        .icon {
          margin: 25px 18px;
          position: absolute;
          height: 50px;
          left: 10px;
          .svg-icon {
            height: 25px;
            width: 25px;
            color: #fff;
            padding: 12.5px;
            background-color: $mainColor;
          }
        }
        .line2 {
          position: absolute;
          height: 100px;
          left: 50px;
          top: 50px;
          border-left: 4px dashed $fontColor3;
        }
      }
      .listItemRight {
        background-color: #fff;
        margin-top: 10px;
        width: 85%;
        padding: 10px;
        .rightTitle {
          font-size: 20px;
          color: $mainColor;
        }
        .address {
          font-size: 14px;
          line-height: 25px;
        }
        .time {
          font-size: 14px;
        }
      }
    }
    .status1 {
      .listItemLeft {
        .icon {
          .svg-icon {
            background-color: $fontColor3;
          }
        }
      }
      .listItemRight {
        .rightTitle {
          color: $fontColor3;
        }
      }
    }

    .status3 {
      .listItemLeft {
        .icon {
          .svg-icon {
            background-color: $mainColor;
          }
        }
        .line2 {
          border-left: 4px solid $mainColor;
        }
      }
      .listItemRight {
        .rightTitle {
          color: $mainColor;
        }
      }
    }
    .status2 {
      .listItemLeft {
        .icon {
          .svg-icon {
            background-color: $background2;
          }
        }
      }
      .listItemRight {
        .rightTitle {
          color: $background2;
        }
      }
    }
    .NoData {
      margin: 0 auto;
      img {
        margin-top: 70px;
        width: 250px;
      }
      p {
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .el-table {
    border: 1px solid #fff;
    color: $mainColor !important;
  }
  .el-table--fit {
    border-right: 1px solid #fff !important;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell {
    border-bottom: 2px solid $mainColor;
    vertical-align: top;
  }
  .el-table__empty-text {
    height: 350px;
    font-size: 60px;
    color: $borderColor1;
    line-height: 350px;
  }

  .el-collapse-item__header {
    height: auto;
    line-height: 30px;
    padding: 10px 0;
    font-size: 30px;
    color: $fontColor3;
    padding: 20px 0;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #fff;
  }
  .imagaDiv {
    width: 150px;
    position: relative;
    height: 87px;
    margin-top: 20px;
    .image {
      width: 150px;
      position: absolute;
    }
    .index {
      position: absolute;
      font-size: 30px;
      color: rgba(255, 255, 255, 0.75);
      top: 28px;
      left: 15px;
      width: 40px;
      text-align: center;
    }
    .containerType {
      position: absolute;
      font-size: 20px;
      color: #fff;
      top: 25px;
      left: 60px;
      text-align: center;
      width: 80px;
    }
  }
  .DGCargo,
  .RFCargo {
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-top: 20px;
  }
  .DGCargo {
    color: $background3;
    line-height: 14px;
    .svg-icon {
      stroke: none;
      color: $background3;
      margin-right: 10px;
    }
  }
  .RFCargo {
    line-height: 14px;
    .svg-icon {
      height: 28px;
      width: 28px;
    }
  }

  .container-line {
    display: flex;
    font-size: 30px;
    color: $fontColor3;
    .itemNo {
      margin-bottom: 15px;
      margin-top: 15px;
      font-family: "SEMIBOLD";
    }

    .itemNo,
    .MileStone {
      width: 300px;
      .label {
        font-size: 14px;
        font-family: "SEMIBOLD";
      }
      .value {
        font-size: 30px;
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        font-size: 20px;
        margin-top: 10px;
      }
    }
    .Current {
      .value {
        color: $background2;
      }
    }
    .Next {
      .value {
        color: $fontColor3;
      }
    }
  }

  .cell {
    height: 100%;
  }
}
</style>