<template>
  <div
    class="qutationList"
    :class="{ qutationSmall: SearchType == 1 }"
    v-loading="getIsLockLoading"
  >
    <k-second-header
      :data="quotationMenu"
      :clickchange="false"
      @handleSelect="handleSelect"
      @Search="search"
      :showSearch="true"
    ></k-second-header>
    <dwlist
      :url="source.quotation_api.quotationListData"
      :query-params="queryForm"
      ref="postsList10"
      v-show="queryForm.state == 10"
    >
      <el-table-column prop="bookingId" label="ID" min-width="80%">
        <template slot-scope="scope">
          <div class="IDdiv">
            <div class="dian" v-if="scope.row.urgentFlag == 1">
              <i class="el-icon-star-on"></i>
            </div>
            <div>{{ scope.row.quotationNo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="summry"
        :label="$t('lang.BText4')"
        min-width="120%"
      >
        <template slot-scope="scope">
          <div class="Summry">
            <div class="line1">
              {{ quotationTypeObj[scope.row.quotationMainType] }} -
              {{ quotationTypeObj[scope.row.quotationType] }}
            </div>
            <div class="line2">
              <span v-if="scope.row.fromType == 0">{{
                scope.row.fromCyPortName
              }}</span>
              <span v-if="scope.row.fromType == 1"
                >{{ scope.row.fromDoorCityName }},{{
                  scope.row.fromDoorCountry
                }}</span
              >
              <span style="color: #299be4"> -> </span>
              <span v-if="scope.row.toType == 0">{{
                scope.row.toCyPortName
              }}</span>
              <span v-if="scope.row.toType == 1"
                >{{ scope.row.toDoorCityName }},{{
                  scope.row.toDoorCountry
                }}</span
              >
            </div>
            <div class="line3" v-if="scope.row.containerDatas !== ''">
              <div
                v-for="(item, index) in JSON.parse(scope.row.containerDatas)"
                :key="index"
              >
                {{ item.containerSize }}*{{ item.count }};
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliveryDate"
        :label="$t('lang.BText28')"
        min-width="60%"
      >
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.deliveryDate !== ''"
          >
            {{ scope.row.deliveryDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.deliveryDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.deliveryDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateDate"
        :label="$t('lang.NText194')"
        min-width="60%"
      >
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.updateDate !== ''"
          >
            {{ scope.row.updateDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.updateDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.updateDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50" fixed="right" v-if="SearchType == 1">
        <template slot-scope="scope">
          <div class="RoleRight">
            <el-popover placement="bottom" trigger="hover">
              <div class="moreList">
                <div class="list-button-item" @click="Review(scope.row, '3')">
                  {{ $t("lang.QText76") }}
                </div>
                <el-button
                  class="list-button-item"
                  :disabled="scope.row.lockFlag == 1"
                  @click="editQutation(scope.row)"
                >
                  {{ $t("lang.QText77") }}
                </el-button>
                <div
                  class="list-button-item"
                  v-if="scope.row.urgentFlag == 0"
                  @click="click_Urgent(scope.row)"
                >
                  {{ $t("lang.NText195") }}
                </div>
                <div class="list-button-item" @click="click_Delete(scope.row)">
                  {{ $t("lang.BText40") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickSaveAsTemplate(scope.row)"
                >
                  {{ $t("lang.QText78") }}
                </div>
                <!-- <div class="list-button-item" @click="history(scope.row)">
                  {{ $t("lang.QText87") }}
                </div> -->
              </div>
              <div
                class="signOut-icon1"
                :class="{ 'signOut-icon': scope.row.signOut }"
                slot="reference"
              >
                ···
              </div>
            </el-popover>

            <!-- <div class="chat-icon" @click="listChatIcon(scope.row)">
              <svg-icon icon-class="chat"></svg-icon>
            </div> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" v-if="SearchType == 2">
        <template slot-scope="scope">
          <div class="RoleRight2">
            <el-button
              :disabled="scope.row.lockFlag == 1"
              @click="editQutation(scope.row)"
              class="samll-list-primary"
              >{{ $t("lang.QText77") }}</el-button
            >
            <el-button
              @click="Review(scope.row, '3')"
              class="samll-list-info"
              >{{ $t("lang.QText76") }}</el-button
            >
            <el-popover placement="bottom" trigger="hover">
              <div class="moreList">
                <div
                  class="list-button-item"
                  v-if="scope.row.urgentFlag == 0"
                  @click="click_Urgent(scope.row)"
                >
                  {{ $t("lang.NText195") }}
                </div>
                <div class="list-button-item" @click="click_Delete(scope.row)">
                  {{ $t("lang.BText40") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickSaveAsTemplate(scope.row)"
                >
                  {{ $t("lang.QText78") }}
                </div>
                <!-- <div class="list-button-item" @click="history(scope.row)">
                  {{ $t("lang.QText87") }}
                </div> -->
              </div>
              <el-button slot="reference" class="samll-list-info"
                >{{ $t("lang.BText109")
                }}<svg-icon class="icon" icon-class="downTriangle"></svg-icon
              ></el-button>
            </el-popover>

            <!-- <div class="chat-icon" @click="listChatIcon(scope.row)">
              <svg-icon icon-class="chat"></svg-icon>
            </div> -->
          </div>
        </template>
      </el-table-column>
    </dwlist>

    <dwlist
      :url="source.quotation_api.quotationListData"
      :query-params="queryForm"
      ref="postsList20"
      v-show="queryForm.state == 20 || queryForm.state == 30"
    >
      <el-table-column prop="bookingId" label="ID" min-width="180%">
        <template slot-scope="scope">
          <div class="IDdiv">
            <div class="dian" v-if="scope.row.urgentFlag == 1">
              <i class="el-icon-star-on"></i>
            </div>
            <div>{{ scope.row.quotationNo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="summry"
        :label="$t('lang.BText4')"
        min-width="220%"
      >
        <template slot-scope="scope">
          <div class="Summry">
            <div class="line1">
              {{ quotationTypeObj[scope.row.quotationMainType] }} -
              {{ quotationTypeObj[scope.row.quotationType] }}
            </div>
            <div class="line2">
              <span v-if="scope.row.fromType == 0">{{
                scope.row.fromCyPortName
              }}</span>
              <span v-if="scope.row.fromType == 1"
                >{{ scope.row.fromDoorCityName }},{{
                  scope.row.fromDoorCountry
                }}</span
              >
              <span style="color: #299be4"> -> </span>
              <span v-if="scope.row.toType == 0">{{
                scope.row.toCyPortName
              }}</span>
              <span v-if="scope.row.toType == 1"
                >{{ scope.row.toDoorCityName }},{{
                  scope.row.toDoorCountry
                }}</span
              >
            </div>
            <div class="line3" v-if="scope.row.containerDatas !== ''">
              <span
                v-for="(item, index) in JSON.parse(scope.row.containerDatas)"
                :key="index"
                >{{ item.containerSize }}*{{ item.count }};
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="headAmounts"
        :label="$t('lang.BText118')"
        min-width="260%"
      >
        <template slot="header">
          <div class="head-amounts">{{ $t("lang.BText118") }}</div>
        </template>
        <template slot-scope="scope">
          <div
            class="Amount"
            v-for="(item, index) in scope.row.headAmounts"
            :key="index"
          >
            <div class="amountType">
              <span
                v-if="
                  item.amountType == 0 &&
                  (index == 0 ||
                    (index != 0 &&
                      item.amountType !=
                        scope.row.headAmounts[index - 1].amountType))
                "
                >{{ $t("lang.BText23") }}</span
              ><span
                v-if="
                  item.amountType == 1 &&
                  (index == 0 ||
                    (index != 0 &&
                      item.amountType !=
                        scope.row.headAmounts[index - 1].amountType))
                "
                >{{ $t("lang.BText119") }}</span
              ><span
                v-if="
                  item.amountType == 2 &&
                  (index == 0 ||
                    (index != 0 &&
                      item.amountType !=
                        scope.row.headAmounts[index - 1].amountType))
                "
                >{{ $t("lang.BText26") }}</span
              >
            </div>
            <div class="headCurrency">
              {{ item.headCurrency }}
            </div>
            <div class="headAmount">
              {{ item.headAmount }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliveryDate"
        :label="$t('lang.BText28')"
        width="150px"
      >
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.deliveryDate !== ''"
          >
            {{ scope.row.deliveryDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.deliveryDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.deliveryDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateDate"
        :label="$t('lang.NText194')"
        width="150px"
      >
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.updateDate !== ''"
          >
            {{ scope.row.updateDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.updateDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.updateDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="repliedDate"
        :label="$t('lang.NText196')"
        width="150px"
      >
        <template slot-scope="scope">
          <div class="RepliedDate" v-if="$i18n.locale == 'zh_cn'">
            {{ scope.row.repliedDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.repliedDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.repliedDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="70%" fixed="right" v-if="SearchType == 1">
        <template slot-scope="scope">
          <div class="RoleRight">
            <el-popover placement="bottom" trigger="hover">
              <div class="moreList">
                <div class="list-button-item" @click="Review(scope.row, '2')">
                  {{ $t("lang.QText76") }}
                </div>
                <el-button
                  class="list-button-item"
                  @click="Booking(scope.row)"
                  :disabled="scope.row.amountConfirmFlag != 1"
                  type="text"
                >
                  {{ $t("lang.BText35") }}
                </el-button>
                <div
                  class="list-button-item"
                  v-if="
                    scope.row.incotermsType == '1' &&
                    scope.row.amountConfirmFlag == '1' &&
                    scope.row.relType != '1'
                  "
                  @click="click_Share(scope.row)"
                >
                  {{ $t("lang.NText197") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickSaveAsTemplate(scope.row)"
                >
                  {{ $t("lang.QText78") }}
                </div>
                <!-- <div class="list-button-item" @click="history(scope.row)">
                  {{ $t("lang.QText87") }}
                </div> -->
              </div>
              <div
                class="signOut-icon1"
                :class="{ 'signOut-icon': scope.row.signOut }"
                slot="reference"
              >
                ···
              </div>
            </el-popover>
            <!-- <div class="chat-icon" @click="listChatIcon(scope.row)">
              <svg-icon icon-class="chat"></svg-icon>
            </div> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="280%" fixed="right" v-if="SearchType == 2">
        <template slot-scope="scope">
          <div class="RoleRight2">
            <el-button
              :disabled="
                scope.row.amountConfirmFlag != 1"
              @click="Booking(scope.row)"
              class="samll-list-primary"
              v-show="queryForm.state == 20"
              >{{ $t("lang.BText35") }}</el-button
            >
            <el-button
              @click="Review(scope.row, '2')"
              class="samll-list-info"
              >{{ $t("lang.QText76") }}</el-button
            >
            <el-popover placement="bottom" trigger="hover" v-show="queryForm.state == 20">
              <div class="moreList">
                <div
                  class="list-button-item"
                  v-if="
                    scope.row.incotermsType == '1' &&
                    scope.row.amountConfirmFlag == '1' &&
                    scope.row.relType != '1'
                  "
                  @click="click_Share(scope.row)"
                >
                  {{ $t("lang.NText197") }}
                </div>

                <div
                  class="list-button-item"
                  @click="clickSaveAsTemplate(scope.row)"
                >
                  {{ $t("lang.QText78") }}
                </div>
                <!-- <div class="list-button-item" @click="history(scope.row)">
                  {{ $t("lang.QText87") }}
                </div> -->
              </div>
              <el-button slot="reference" class="samll-list-info"
                >{{ $t("lang.BText109")
                }}<svg-icon class="icon" icon-class="downTriangle"></svg-icon
              ></el-button>
            </el-popover>
            <!-- <div class="chat-icon" @click="listChatIcon(scope.row)">
              <svg-icon icon-class="chat"></svg-icon>
            </div> -->
          </div>
        </template>
      </el-table-column>
    </dwlist>

    <div class="close" v-show="searchForm" @click="searchForm = false">
      <svg-icon icon-class="close"></svg-icon>
    </div>
    <div class="search-div" v-show="searchForm">
      <el-form>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title required">
              {{ $t("lang.NText142") }}
            </div>
            <el-cascader
              v-model="choosequotationType"
              :options="quotationType"
              @change="handleChange"
              :placeholder="$t('lang.BText80')"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.dictLabel }}</span>
              </template></el-cascader
            >
          </el-form-item>
          <el-form-item class="form-item">
            <div class="form-item-title"></div>
            <el-button type="info" @click="click_Clear">{{
              $t("lang.NText302")
            }}</el-button>
          </el-form-item>
          <el-form-item class="form-item">
            <div class="form-item-title"></div>
            <el-button type="primary" @click="query()">{{
              $t("lang.BText108")
            }}</el-button>
          </el-form-item>
        </div>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title">{{ $t("lang.QText13") }}</div>
            <el-select
              class="step1-select"
              v-model="queryForm.fromCyPort"
              :placeholder="$t('lang.BText80')"
              filterable
              remote
              clearable
              :remote-method="findPortsList"
              :loading="selectLoading"
              ref="fromCyPort"
            >
              <el-option
                v-for="item in fromCyPortList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form-item">
            <div class="form-item-title">{{ $t("lang.QText14") }}</div>
            <el-select
              class="step1-select"
              v-model="queryForm.toCyPort"
              :placeholder="$t('lang.BText80')"
              filterable
              remote
              clearable
              :remote-method="findPortsList2"
              :loading="selectLoading2"
              ref="fromCyPort"
            >
              <el-option
                v-for="item in toCyPortList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title required">
              {{ $t("lang.BText118") }}
            </div>
            <el-input v-model="queryForm.amountStart"> </el-input>
          </el-form-item>
          <div class="middle">—</div>
          <el-form-item class="form-item">
            <div class="form-item-title"></div>
            <el-input v-model="queryForm.amountEnd"> </el-input>
          </el-form-item>
        </div>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title">{{ $t("lang.BText28") }}</div>
            <el-date-picker
              v-model="queryForm.deliveryStartDate"
              type="date"
              :placeholder="$t('lang.NText300')"
              value-format="yyyy-MM-dd"
              :picker-options="deliveryStartDateOptions"
              @change="deliveryStartDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="form-item">
            <div class="form-item-title"></div>
            <el-date-picker
              v-model="queryForm.deliveryEndDate"
              type="date"
              :placeholder="$t('lang.NText209')"
              value-format="yyyy-MM-dd"
              :picker-options="deliveryEndDateOptions"
              @change="deliveryEndDateChange"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title">{{ $t("lang.NText301") }}</div>
            <el-date-picker
              v-model="queryForm.repliedStartDate"
              type="date"
              :placeholder="$t('lang.NText300')"
              value-format="yyyy-MM-dd"
              :picker-options="repliedStartDateOptions"
              @change="repliedStartDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="form-item">
            <div class="form-item-title"></div>
            <el-date-picker
              v-model="queryForm.repliedEndDate"
              type="date"
              :placeholder="$t('lang.NText209')"
              value-format="yyyy-MM-dd"
              :picker-options="repliedEndDateOptions"
              @change="repliedEndDateChange"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-line">
          <el-form-item class="form-item">
            <div class="form-item-title">{{ $t("lang.QText82") }}</div>
            <el-input v-model="queryForm.quotationNo"> </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog
      :visible.sync="shareFlag"
      width="70%"
      class="createDialog"
      v-loading="shareLoading"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon
            icon-class="share"
            class="svg-icon"
            style="color: '#FFF'"
          ></svg-icon>
          {{ $t("lang.NText208") }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="dialog-main">
        <el-form
          class="booking-create-form"
          :model="shareForm"
          :rules="shareFormRules"
          ref="ruleForm"
        >
          <div class="dw-card2">
            <div class="title">{{ $t("lang.SText49") }}</div>
            <div class="main">
              <el-form-item class="form-item" prop="toUserId">
                <div class="form-item-title">{{ $t("lang.SText49") }}</div>
                <el-select
                  class="weight-select"
                  v-model="shareForm.toUserId"
                  placeholder=""
                >
                  <el-option
                    v-for="item in partnerList"
                    :key="item.accountId"
                    :label="item.name"
                    :value="item.accountId"
                  >
                  </el-option>
                </el-select>
                <!-- <el-cascader
                  :options="partnerOptions"
                  :props="{ lazy: true, lazyLoad }"
                  v-model="shareForm.toUserId"
                ></el-cascader> -->
              </el-form-item>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.NText203") }}</div>
            <div class="main">
              <el-form-item class="form-item radio" prop="shareSecrecy">
                <div class="Incoterms-radio">
                  <el-radio v-model="shareForm.shareSecrecy" label="0">
                    {{ $t("lang.NText204") }}
                  </el-radio>
                  <el-radio v-model="shareForm.shareSecrecy" label="1">
                    {{ $t("lang.NText205") }}
                  </el-radio>
                  <el-radio v-model="shareForm.shareSecrecy" label="2">
                    {{ $t("lang.NText206") }}
                  </el-radio>
                  <el-radio v-model="shareForm.shareSecrecy" label="3">
                    {{ $t("lang.NText207") }}
                  </el-radio>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="button">
            <el-button type="primary" @click="Share()">{{
              $t("lang.NText197")
            }}</el-button>
            <el-button type="info" @click="shareFlag = false">{{
              $t("lang.QText79")
            }}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="createBookingFlag"
      width="30%"
      class="createBookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="line line1">
        {{ $t("lang.NText198") }}
        <div class="lineMain">{{ thisRow.quotationNo }}</div>
      </div>
      <div class="line">
        {{ $t("lang.NText199") }}
      </div>
      <div class="line">
        {{ $t("lang.NText200") }}:
        <div class="lineMain">
          {{ $t("lang.NText201") }}
        </div>
      </div>
      <div class="button">
        <el-button class="cancelButton" @click="createBookingFlag = false">{{
          $t("lang.QText79")
        }}</el-button>
        <el-button type="primary" @click="createBooking()">{{
          $t("lang.NText202")
        }}</el-button>
      </div>
    </el-dialog>
    <dw-check-box
      :showCheckBox="showSaveTemplate"
      @Cancel="
        showSaveTemplate = false;
        template = {};
      "
      @Save="saveAsTemplate"
      :primaryButton="$t('lang.SText13')"
      :CancelButton="$t('lang.QText79')"
      :loading="saveAsTemplateLoading"
    >
      {{ $t("lang.QText92") }} *
      <el-form :model="template" ref="template">
        <el-form-item
          class="form-item"
          prop="templateName"
          label=""
          :rules="[
            {
              required: true,
              message: $t('lang.SText143'),
            },
            { max: 20, message: $t('lang.SText154'), trigger: 'change' },
          ]"
        >
          <el-input v-model="template.templateName"></el-input>
        </el-form-item>
      </el-form>
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showUrgent"
      @Cancel="showUrgent = false"
      @Save="urgentQuotation"
      :primaryButton="$t('lang.NText195')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.Urgent") }}
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showDelete"
      @Cancel="showDelete = false"
      @Save="deleteQuotation"
      :primaryButton="$t('lang.BText40')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.delete") }}
    </dw-check-box>
    <el-dialog
      v-if="showReview"
      :visible.sync="showReview"
      class="reviewDialog dw-dialog"
      width="70%"
      @close="
        showReview = false;
        query();
      "
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.NText73") }}</div>
          <div class="row2">
            ID:<span style="color: #fff"> {{ thisRow.quotationNo }}</span>
          </div>
        </div>
        <div class="titleDate" v-if="showReview">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText75") }}</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.updateDate)
              }}</span
              ><span v-else>{{ thisRow.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText72") }}</div>
            <div class="date2" v-if="thisRow.expiredDate">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.expiredDate)
              }}</span
              ><span v-else>{{ thisRow.expiredDate }}</span>
            </div>
          </div>
        </div>
        <div class="close" @click="showReview = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <quotation-dialog
          :quotationData="quotationData"
          @confirmQuotationCharges="query"
          :detailDialogType="detailDialogType"
        ></quotation-dialog>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showEditFLag"
      :visible.sync="showEditFLag"
      class="editDialog dw-dialog"
      width="70%"
      @close="
        showEditFLag = false;
        edit = false;
        review = false;
      "
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.QText89") }}</div>
          <div class="row2">
            ID:<span style="color: #fff"> {{ thisRow.quotationNo }}</span>
          </div>
        </div>

        <div class="close" @click="showEditFLag = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <edit-dialog
          v-if="edit"
          :quotationData="thisRow"
          @editReview="editReview"
          @cancelEdit="
            showEditFLag = false;
            edit = false;
            review = false;
          "
        ></edit-dialog>
        <review
          v-if="review"
          :quotationData="JSON.stringify(thisRow)"
          @edit="
            edit = true;
            review = false;
          "
          @SaveSuccess="
            showEditFLag = false;
            query();
          "
        ></review>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dwlist from "../../../components/dwList/dwlist.vue";
import KSecondHeader from "../../../components/kHeader/kSecondHeader.vue";
import test from "../../../test/test";
import dwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import QuotationDialog from "../quotationDialog/quotationDialog.vue";
import utils from "../../../utils/util";
import editDialog from "../aQuote/edit.vue";
import review from "../aQuote/Review.vue";

export default {
  components: {
    dwlist,
    KSecondHeader,
    dwCheckBox,
    QuotationDialog,
    editDialog,
    review,
  },
  data() {
    return {
      quotationMenu: [
        {
          menuName: "Inquiry",
          extend: {
            extendS3: "询价",
          },
        },
        {
          menuName: "Quotation",
          extend: {
            extendS3: "报价",
          },
        },
        {
          menuName: "Expired",
          extend: {
            extendS3: "过期",
          },
        },
      ],
      listData: test.listData,
      tab: "0",
      searchForm: false,
      shareFlag: false,
      form: {},
      listloading: false,
      userList: [
        {
          value: "Grey Chen1",
          label: "Grey Chen1",
          email: "grey@de-well.com",
        },
        {
          value: "Terry Chen",
          label: "Terry Chen",
          email: "grey@de-well.com",
        },
        {
          value: "Grey Chen2",
          label: "Grey Chen2",
          email: "grey@de-well.com",
        },
        {
          value: "Grey Chen3",
          label: "Grey Chen3",
          email: "grey@de-well.com",
        },
      ],
      queryForm: {
        state: "10",
      },
      userInfo: {},
      showSaveTemplate: false,
      template: {},
      quotationType: [],
      quotationTypeObj: {},
      choosequotationType: {},
      rules: [],
      showUrgent: false,
      showDelete: false,
      quotationId: "",
      showReview: false,
      shareForm: {},
      shareFormRules: {},
      partnerOptions: [
        {
          value: "Shipper",
          label: "Shipper",
        },
        {
          value: "Consignee",
          label: "Consignee",
        },
      ],
      shareLoading: false,
      quotationData: {},
      partnerList: [],
      state: "10",
      thisRow: {},
      showEditFLag: false,
      signOut: false,
      utils: utils,
      signOut2: false,
      signOut4: false,
      signOut3: false,
      createBookingFlag: false,
      review: false,
      edit: false,
      saveAsTemplateLoading: false,
      getIsLockLoading: false,
      deliveryEndDateOptions: {},
      deliveryStartDateOptions: {},
      repliedEndDateOptions: {},
      repliedStartDateOptions: {},
      detailDialogType: "",
      fromCyPortList: [],
      selectLoading: false,
      selectLoading2: false,
      toCyPortList: [],
    };
  },
  computed: {
    SearchType() {
      if (window.innerWidth < 1660) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
      this.queryForm.companyId = this.userInfo.companyid;
    });
    this.$session("quotationType").get((value) => {
      this.quotationType = value;
      this.quotationType.forEach((item) => {
        item.label = item.dictLabel;
        item.value = item.dictValue;
        this.quotationTypeObj[item.dictValue] = item.dictLabel;
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            item2.label = item2.dictLabel;
            item2.value = item2.dictValue;
            this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
          });
        }
      });
    });
  },
  methods: {
    query() {
      this.searchForm = false;
      this.queryForm.companyId = this.userInfo.companyid;
      if (this.queryForm.state == 10) {
        this.$refs.postsList10.query();
      }
      if (this.queryForm.state == 20 || this.queryForm.state == 30) {
        this.$refs.postsList20.query();
      }
    },
    findPortsList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            this.fromCyPortList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    findPortsList2(e) {
      this.selectLoading2 = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading2 = false;
          if ((res.resultCode = "success")) {
            this.toCyPortList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    deliveryStartDateChange() {
      let that = this;
      this.deliveryEndDateOptions = {
        disabledDate(time) {
          if (that.queryForm.deliveryStartDate) {
            return (
              time.getTime() <
              new Date(that.queryForm.deliveryStartDate).getTime()
            );
          } else {
            return;
          }
        },
      };
    },
    deliveryEndDateChange() {
      let that = this;
      this.deliveryStartDateOptions = {
        disabledDate(time) {
          if (that.queryForm.deliveryEndDate) {
            return (
              time.getTime() >
                new Date(that.queryForm.deliveryEndDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return;
          }
        },
      };
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    repliedStartDateChange() {
      let that = this;
      this.repliedEndDateOptions = {
        disabledDate(time) {
          if (that.queryForm.repliedStartDate) {
            return (
              time.getTime() <
              new Date(that.queryForm.repliedStartDate).getTime()
            );
          } else {
            return;
          }
        },
      };
    },
    repliedEndDateChange() {
      let that = this;
      this.repliedStartDateOptions = {
        disabledDate(time) {
          if (that.queryForm.repliedEndDate) {
            return (
              time.getTime() >
                new Date(that.queryForm.repliedEndDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return;
          }
        },
      };
    },

    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    editReview(e) {
      this.thisRow = e.submitForm;
      this.review = true;
      this.edit = false;
    },
    click_Clear() {
      console.log(this.state);
      this.queryForm = {
        companyId: this.userInfo.companyid,
        state: this.state,
      };
      this.choosequotationType = [];
    },
    lazyLoad() {
      this.$ajax
        .cPost(this.source.quotation_api.findPartnerList, {
          shareRoleType: "Shipper",
        })
        .then((res) => {
          if (res.data.result == "true") {
            if (res.data.data.length == 0) {
              this.$notify.error({
                title: this.$t("lang.QText105"),
                offset: 100,
                duration: 3000,
              });
            } else {
              this.partnerList = res.data.data;
              this.shareFlag = true;
            }
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    showMore(data, index) {
      let Index = index + 1;
      if (!this.thisRow.index || this.thisRow.index != Index) {
        this.thisRow = data;
        this.signOut2 = true;
        this.thisRow.index = Index;
        return;
      } else {
        this.signOut2 = false;
        this.thisRow = {};
      }
    },
    showMore2(data, index) {
      let Index = index + 1;
      if (!this.thisRow.index || this.thisRow.index != Index) {
        this.thisRow = data;
        this.signOut4 = true;
        this.thisRow.index = Index;
        return;
      } else {
        this.signOut4 = false;
        this.thisRow = {};
      }
    },
    signOutHover(data, type, index) {
      if (type) {
        // this.$set(data, "signOut", false);
        this.signOut = false;
        return;
      }
      // this.$set(data, "signOut", true);
      this.thisRow = data;
      this.thisRow.index = index;
      this.signOut = true;
    },
    signOutHover2(data, type, index) {
      if (type) {
        // this.$set(data, "signOut", false);
        this.signOut3 = false;
        return;
      }
      // this.$set(data, "signOut", true);
      this.thisRow = data;
      this.thisRow.index = index;
      this.signOut3 = true;
    },
    closeDialog() {
      this.editFlag = false;
    },
    handleSelect(num) {
      let data = parseInt(num) + 1;
      this.queryForm.state = data + "0";
      this.state = data + "0";
      this.query();
    },
    handleChange(type) {
      this.queryForm.quotationMainType = type[0];
      this.queryForm.quotationType = type[1];
    },
    search() {
      this.searchForm = true;
      this.findPortsList();
      this.findPortsList2();
    },
    closeDialog() {
      this.shareFlag = false;
    },
    click_Share(data) {
      this.thisRow = data;
      if (data.amountConfirmFlag == 1) {
        this.lazyLoad();
        this.shareForm.quotationId = data.id;
      } else {
        this.$notify.info({
          title: "Please confirm the quotation first",
          offset: 100,
          duration: 3000,
        });
      }
    },
    click_Urgent(data) {
      this.thisRow = data;
      this.quotationId = data.id;
      this.showUrgent = true;
      this.signOut2 = false;
      this.signOut4 = false;
    },
    click_Delete(data) {
      this.thisRow = data;
      this.quotationId = data.id;
      this.showDelete = true;
      this.signOut2 = false;
      this.signOut4 = false;
    },
    deleteQuotation() {
      this.$ajax
        .cPost(this.source.quotation_api.deleteQuotation, {
          quotationId: this.quotationId,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.showDelete = false;
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    urgentQuotation() {
      this.$ajax
        .cPost(this.source.quotation_api.urgentQuotation, {
          quotationId: this.quotationId,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.showUrgent = false;
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    clickSaveAsTemplate(data) {
      this.thisRow = data;
      this.template.quotationId = data.id;
      this.showSaveTemplate = true;
      this.signOut2 = false;
      this.signOut4 = false;
    },
    saveAsTemplate() {
      this.$refs.template.validate((valid) => {
        if (valid) {
          this.saveAsTemplateLoading = true;
          this.$ajax
            .cPost(this.source.quotation_api.saveForTemplate, this.template)
            .then((res) => {
              if (res.data.result == "true") {
                this.$notify.success({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
                this.showSaveTemplate = false;
              } else {
                this.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
              this.saveAsTemplateLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    Cancel() {
      this.showSaveTemplate = false;
    },
    editQutation(data) {
      // this.shareForm.toUserId = this.shareForm.toUserId[1];
      this.getIsLockLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.getIsLock, { qid: data.id })
        .then((res) => {
          this.getIsLockLoading = false;
          if (res.data.result == "true") {
            if (res.data.isLock == "false") {
              this.showEditFLag = true;
              this.edit = true;
              this.signOut2 = false;
              this.signOut3 = false;
              this.signOut4 = false;
              this.review = false;
              this.thisRow = {};
              this.thisRow = JSON.parse(JSON.stringify(data));
            } else {
              this.$notify.error({
                title: "The order is in quotation and cannot be modified.",
                offset: 100,
                duration: 3000,
              });
            }
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });

      // this.$router.push({ name: "Edit", params: { quotataion: data } });
    },
    Review(data, number) {
      this.detailDialogType = number;
      this.$set(this, "quotationData", JSON.stringify(data));
      this.thisRow = data;
      this.signOut2 = false;
      this.signOut3 = false;
      this.signOut4 = false;
      setTimeout(() => {
        this.showReview = true;
      }, 20);
    },
    Share() {
      this.shareLoading = true;
      // this.shareForm.toUserId = this.shareForm.toUserId[1];
      this.$ajax
        .cPost(this.source.quotation_api.shareQuotation, this.shareForm)
        .then((res) => {
          this.shareLoading = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.shareFlag = false;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    listChatIcon(row) {
      this.signOut2 = false;
      this.$emit("drawerClick", { num: row.quotationNo, type: "Quotation" });
      // this.$emit("drawerClick", { btype: "10", bid: row.id });
    },
    history(row) {
      this.signOut2 = false;
      this.signOut4 = false;
      this.$emit("historyClick", { num: row.quotationNo, type: "Quotation" });
    },
    Booking(row) {
      this.creatLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.bookingActivation, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.thisRow = row;
            this.createBookingFlag = true;
            this.signOut3 = false;
            this.signOut4 = false;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    createBooking() {
      let row = this.thisRow;
      console.log(row);
      var form = {
        dataSource: "2",
        airSea: row.quotationMainType,
        bookingType: row.quotationType,
        originPort: row.crmFromPort,
        originPortName: row.crmFromPortName,

        originAddress: row.fromDoorAddress,
        originCountryRegion: row.fromDoorCountry,
        originCity: row.fromDoorCity,
        originState: row.fromDoorState,
        originZipCode: row.fromDoorZipCode,

        destinationDischargePort: row.crmToPort,
        destinationDischargePortName: row.crmToPortName,
        destinationAddress: row.toDoorAddress,
        destinationCountryRegion: row.toDoorCountry,
        destinationCity: row.toDoorCity,
        destinationState: row.toDoorState,
        destinationZipCode: row.toDoorZipCode,

        cargoReadyDay: row.cargoReadyDate,
        destinationTargetDeliveryDate: row.deliveryDate,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantityUnit: row.totalQuantitUnit,
        incotermOne: row.incotermsType,
        incotermTwo: row.incotermsDataType,
        cargoType: row.cargoType,
        cargoDetails: "1",
        commodityHscodes: row.commodityHscodes,
        commodityHscodesName: row.commodityHscodesName,
        notificationRemark: row.masks,
        quotationNo: row.quotationNo,
        quotationId: row.id,
        cargos: [],

        cargoTypeName: row.cargoTypeName,
        originCountryRegionName: row.fromDoorCountryName,
        originCityName: row.fromDoorCityName,
        originStateName: row.fromDoorStateName,
        destinationCountryRegionName: row.toDoorCountryName,
        destinationCityName: row.toDoorCityName,
        destinationStateName: row.toDoorStateName,
      };
      form.containerType = [];
      if (row.containerDatas !== "" && row.containerDatas) {
        let bb = JSON.parse(row.containerDatas);
        bb.forEach((item, index) => {
          let cc = {
            containerTypOne: item.containerSize,
            containerTypTwo: item.count,
          };
          form.containerType.push(cc);
        });
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        let aa = JSON.parse(row.dangerousGoodsType);
        form.totalBatteries = aa.indexOf("0") == -1 ? "0" : "1";
        form.hazardousMaterial = aa.indexOf("1") == -1 ? "0" : "1";
        form.totalOther = aa.indexOf("2") == -1 ? "0" : "1";
        form.lithiumBattery = aa.indexOf("3") == -1 ? "0" : "1";
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPickUp = "1";
              // form.originAddress = item.address;
              // form.originPickUpAddressId = item.addressId;
              // form.originCountryRegion = item.country;
              // form.originCity = item.city;
              // form.originState = item.countryState;
              // form.originZipCode = item.zipCode;
              // form.originPickUpCountryName = item.countryName;
              // form.originPickUpCityName = item.cityName;
              // form.originPickUpStateName = item.countryStateName;
              break;
            case "1":
              form.originCustomerClearance = "1";
              break;
            case "2":
              form.originInsurance = "1";
              form.originValue = item.insurVlaue;
              form.originCurrency = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWarehouse = "1";
              form.originValueAddedSevice = item.warehouse;
              break;
            case "6":
              form.destinationDelivery = "1";
              // form.destinationAddress = item.address;
              // form.destinationDelivAddressId = item.addressId;
              // form.destinationCountryRegion = item.country;
              // form.destinationCity = item.city;
              // form.destinationState = item.countryState;
              // form.destinationDelivCountryName = item.countryName;
              // form.destinationDelivCityName = item.cityName;
              // form.destinationDelivStateName = item.countryStateName;
              // form.destinationZipCode = item.zipCode;
              break;
            case "7":
              form.customerClearance = "1";
              break;
            case "8":
              form.destinationBound = "1";
              if (item.bondType == "1") {
                form.destinationBoundValue = "Single bond";
              } else {
                form.destinationBoundValue = "Annual bond";
              }
              break;
            case "9":
              form.destinationWarehouse = "1";
              form.destinationWarehouseValue = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocumentation = "1";
              break;

            default:
              break;
          }
        });
      }
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Booking";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      console.log(form);
      this.$router.push({
        name: "Booking",
        params: { bookingData: form, fillInFormQ: true },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.qutationSmall {
  width: 900px;
}
.qutationList {
  // width: 860px;
  padding-bottom: 100px;
  position: relative;
  min-height: 1000px;
  .kSecondHeader {
    margin-bottom: 0;
  }
  .CargoReadyDay {
    display: flex;
    justify-content: space-between;
  }
  .RoleRight {
    height: 100px;
    line-height: 40px;
    margin: auto;
    width: 36px;
  }
  .RepliedDate {
    width: 140px;
    font-family: "montserratLight";
  }
  .RoleRight2 {
    display: flex;
    justify-content: end;
    .icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: -5px;
    }
  }

  .el-table__cell {
    vertical-align: top;
  }

  .el-dialog {
    margin-top: 0 !important;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
    .dialog-main {
      width: 770px;
      margin: 40px auto;
      position: relative;
      height: 700px;
      text-align: left;
      .dialog-main-title {
        font-size: 30px;
        color: $mainColor;
        border-bottom: 2px solid $mainColor;
        padding-bottom: 30px;
        text-align: left;
      }
      .dialog-main-content {
        display: flex;
        padding-top: 40px;
        height: 400px;
      }
      .Incoterms-radio {
        display: flex;
        flex-direction: column;
        color: $mainColor;
        .el-radio {
          padding: 10px 0;
        }
      }
    }
    .dialog-header {
      height: 105px;
      padding: 20px;
      background-color: $mainColor;
      display: flex;
      .dw-logo {
        width: 70px;
        height: 70px;
        padding: 15px;
        .svg-icon {
          width: 70px;
          height: 70px;
          color: #fff;
        }
      }
      .close {
        width: 50px;
        height: 50px;
        margin-top: 30px;
        margin-left: 100px;
        cursor: pointer;
        .close-icon {
          width: 50px;
          height: 50px;
          fill: #fff;
          stroke: #fff;
        }
      }
      .dialog-title {
        color: #fff;
        margin-left: 90px;
        text-align: left;
        width: 600px;

        .row1 {
          margin-top: 5px;
        }
        .row2 {
          margin-top: 14px;
          color: $fontColor1;
        }
      }
      .titleDate {
        display: flex;
        border-bottom: 2px solid #fff;
        padding-top: 20px;
        text-align: left;
        height: 60px;
        .updateDate {
          .date {
            font-size: 20px;
            line-height: 30px;
            color: #fff;
            margin-right: 20px;
          }
          .date2 {
            font-size: 20px;
            line-height: 30px;
            color: $background2;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 12px;
      right: 30px;
      background-color: $mainColor;
      color: #fff;
      padding: 4px;
    }
  }

  .createBookingDialog {
    margin-top: 100px;
    height: 300px;
    text-align: left;
    line-height: 20px;
    .el-dialog__body {
      padding: 30px;
      font-size: 16px;
      color: $mainColor;
      .line {
        margin-bottom: 20px;
      }
      .line1 {
        display: flex;
        .lineMain {
          margin-left: 10px;
        }
      }
      .lineMain {
        color: $background2;
      }
      .button {
        display: flex;
        justify-content: flex-end;
        .el-button,
        .dw-button {
          height: 48px;
          width: 130px;
          text-align: center;
          font-size: 20px;
          line-height: 48px;
        }
        .cancelButton {
          width: 130px !important;
          margin-left: 30px;
          padding: 0;
          height: 48px;
          line-height: 48px;
          border-radius: 0;
          border: 1px solid $mainColor;
          color: $mainColor;
        }
        .cancelButton:hover {
          background-color: $background2;
          color: #fff;
          border: 1px solid $background2;
        }
      }
    }
  }
  .createDialog {
    height: 100%;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      display: none;
    }
  }

  .signOut-icon {
    color: $mainColor;
    width: 48px;
    height: 25px;
    text-align: center;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
  }
  .signOutHover {
    position: absolute;
    right: 10px;
    top: 0px;
    height: 100px;
    width: 340px;
    z-index: 9999;
    .list-button {
      position: absolute;
      right: 0;
      top: 29px;
      background-color: #fff;
      font-size: 16px;
      text-align: right;
      line-height: 35px;
      color: $mainColor;
      cursor: pointer;
      padding: 10px;
      font-family: "montserratLight";
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      .el-button {
        border: 0 !important;
        background-color: #fff;
        color: $mainColor;
        font-weight: 100;
        font-family: "montserratLight";
      }
    }
    .list-button-item {
      padding: 0 5px;
    }
    .list-button-item:hover {
      color: $background2;
    }
  }
  .signOut-icon1 {
    color: #dde0e6;
    font-size: 50px;
    cursor: pointer;
    line-height: 25px;
    text-align: center;
    width: 40px;
    margin-bottom: 10px;
  }

  .signOutHover2 {
    left: 100px;
  }
  .search-div {
    position: absolute;
    top: 43px;
    right: 0;
    background-color: $background1;
    border: 1px solid $mainColor;
    padding: 30px;
    text-align: left;
    color: $fontColor2;
    z-index: 999;
    width: 838px;
    .form-line {
      display: flex;
      justify-content: space-between;
      .el-input__inner {
        width: 370px;
      }
      .form-item-title {
        height: 40px;
        font-size: 20px;
      }
    }
    .middle {
      margin-top: 40px;
      line-height: 60px;
    }
    .el-button--info {
      min-width: 150px !important;
      margin-left: 40px;
    }
  }
  .close {
    position: absolute;
    top: 12px;
    right: 0;
    background-color: $mainColor;
    color: #fff;
    padding: 4px;
    .svg-icon {
      fill: #fff;
      stroke: #fff;
    }
  }
  .IDdiv {
    display: flex;
    .dian {
      color: $mainColor;
      font-weight: bold;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .gantan {
    color: #eb4b4b;
    font-size: 20px;
  }
  .icon {
    .svg-icon {
      color: $background2;
      margin: 0 5px;
    }
  }
  .chat-icon {
    .svg-icon {
      margin: 10px;
    }
  }
  .Summry {
    font-family: "SEMIBOLD";
    .line1 {
      font-size: 16px;
      color: $background2;
      border-bottom: 1px solid $borderColor1;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .line2 {
      height: 48px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .line3 {
      display: flex;
    }
    .line2,
    .line3,
    .line5 {
      font-size: 16px;
    }
    .line4 {
      font-size: 14px;
      color: $fontColor3;
      line-height: 14px;
      margin-top: 10px;
    }
  }
  .Amount {
    font-size: 14px;
    // color: $background2;
    font-family: "montserratLight";
    display: flex;
    .amountType {
      width: 120px;
      color: $fontColor1;
    }
    .headCurrency {
      width: 50px;
    }
    .headAmount {
      text-align: right;
    }
  }
  .head-amounts {
    padding-left: 120px;
  }
}
</style>
