<template>
  <div class="editList">
    <div class="Edit-main">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText13") }}</div>
          <div class="main">
            <el-form-item class="form-item radio" prop="fromType">
              <el-radio
                v-model="form.fromType"
                label="0"
                @change="changeFormType"
              >
                CY
              </el-radio>
              <el-radio
                v-model="form.fromType"
                label="1"
                @change="changeFormType"
              >
                Door
              </el-radio>
            </el-form-item>
            <el-form-item
              class="form-item"
              prop="fromCyPort"
              :label="$t('lang.NText177')"
              v-if="form.fromType == 0"
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText100'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                class="step1-select"
                v-model="form.fromCyPort"
                :placeholder="$t('lang.BText80')"
                filterable
                remote
                clearable
                :remote-method="findFormPostsList"
                :loading="selectLoading"
                ref="fromCyPort"
                @change="seLabel('originPort')"
              >
                <el-option
                  v-for="item in fromPortsList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="form.fromType == 1">
              <el-form-item
                class="form-item"
                prop="fromDoorAddress"
                :label="$t('lang.QText50')"
              >
                <el-popover
                  placement="bottom"
                  popper-class="RecentlyEditpopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                        <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    :placeholder="$t('lang.BText41')"
                    v-model="form.fromDoorAddress"
                    resize="none"
                    maxlength="100"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlyEditpopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                      <span v-show="item.address">{{ item.address }}, </span>
                      <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                      <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                      <span v-show="item.countryShow">{{
                        item.countryShow
                      }}</span>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="fromDoorCountry"
                  :label="$t('lang.QText52')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText161'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.fromDoorCountry"
                    :placeholder="$t('lang.BText80')"
                    @change="countryChange($event, 0)"
                    filterable
                    ref="fromDoorCountry"
                  >
                    <el-option
                      v-for="item in countryList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="fromDoorState"
                  :label="$t('lang.QText53')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText160'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.fromDoorState"
                    :placeholder="$t('lang.BText80')"
                    @change="stateChange($event, 0)"
                    filterable
                    :loading="fromDoorStateLoading"
                    ref="fromDoorState"
                  >
                    <el-option
                      v-for="item in fromDoorStateList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="fromDoorCity"
                  :label="$t('lang.QText54')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText159'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.fromDoorCity"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    :loading="fromDoorCityLoading"
                    ref="fromDoorCity"
                  >
                    <el-option
                      v-for="item in fromDoorCityList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="fromDoorZipCode"
                  :label="$t('lang.QText55')"
                >
                  <!-- <div class="form-item-title">Zip code</div> -->
                  <el-input v-model="form.fromDoorZipCode"> </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="cargoReadyDate"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText101'),
                    trigger: 'blur',
                  },
                ]"
                :label="$t('lang.QText21')"
              >
                <el-date-picker
                  v-model="form.cargoReadyDate"
                  type="date"
                  :placeholder="$t('lang.QText23')"
                  :picker-options="pickerOptions"
                  @change="CargoReadyDayChange"
                  value-format="yyyy-MM-dd"
                  :editable="false"
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <div>
              <div class="form-item-title servicesTitle">
                {{ $t("lang.BText79") }}
              </div>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText49')"
                v-model="form.originPickUpFlag"
                true-label="1"
                false-label="0"
                :disabled="form.fromType == 0"
              ></el-checkbox>

              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.BText25')"
                v-model="form.originCustomsClearFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText57')"
                v-model="form.originInsurFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <div class="insurance-line" v-if="form.originInsurFlag == 1">
                <el-form-item
                  class="form-item"
                  prop="originInsurVlaue"
                  :label="$t('lang.QText58')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText103'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    class="insurance-input"
                    v-model="form.originInsurVlaue"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="originInsurCurr"
                  :label="$t('lang.QText59')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText104'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="insurance-select"
                    v-model="form.originInsurCurr"
                    :placeholder="$t('lang.BText80')"
                  >
                    <el-option
                      v-for="item in CurrentyList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-checkbox
                class="quotation-detials-checkbox"
                label="ISF"
                v-model="form.originIsfFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText61')"
                v-model="form.originDocFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                label="AMS"
                v-model="form.originAmsFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                label="ACI"
                v-model="form.originAciFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText63')"
                v-model="form.originWareFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="originWareServices"
                  v-if="form.originWareFlag == 1"
                  :label="$t('lang.NText182')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText183'),
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.originWareServices"
                    :placeholder="$t('lang.BText80')"
                  >
                    <el-option
                      v-for="item in WarehouseList.originWarehouse"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <!-- <el-checkbox-group class="OriginServices-checkbox" v-model="checkList"> -->

            <!-- </el-checkbox-group> -->
          </div>
        </div>

        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText14") }}</div>
          <el-form-item class="form-item radio" prop="fromType">
            <el-radio v-model="form.toType" label="0" @change="changeToType">
              CY
            </el-radio>
            <el-radio v-model="form.toType" label="1" @change="changeToType">
              Door
            </el-radio>
          </el-form-item>
          <div class="main">
            <el-form-item
              class="form-item"
              prop="toCyPort"
              v-if="form.toType == 0"
              :label="$t('lang.QText18')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText179'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                class="step1-select"
                v-model="form.toCyPort"
                :placeholder="$t('lang.BText80')"
                filterable
                remote
                clearable
                :remote-method="findPortsList"
                :loading="selectLoading"
                ref="toCyPort"
              >
                <el-option
                  v-for="item in toPortsList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="form.toType == 1">
              <el-form-item
                class="form-item"
                prop="toDoorAddress"
                :label="$t('lang.NText180')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.SText157'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-popover
                  placement="bottom"
                  popper-class="RecentlyEditpopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                        <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    :placeholder="$t('lang.BText41')"
                    v-model="form.toDoorAddress"
                    resize="none"
                    maxlength="100"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlyEditpopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                      <span v-show="item.address">{{ item.address }}, </span>
                      <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                      <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                      <span v-show="item.countryShow">{{
                        item.countryShow
                      }}</span>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="toDoorCountry"
                  :label="$t('lang.QText52')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText161'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.toDoorCountry"
                    :placeholder="$t('lang.BText80')"
                    @change="countryChange($event, 1)"
                    filterable
                    ref="toDoorCountry"
                  >
                    <el-option
                      v-for="item in countryList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="toDoorState"
                  :label="$t('lang.QText53')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText160'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.toDoorState"
                    :placeholder="$t('lang.BText80')"
                    @change="stateChange($event, 1)"
                    filterable
                    :loading="toDoorStateLoading"
                    ref="toDoorState"
                  >
                    <el-option
                      v-for="item in toDoorStateList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="toDoorCity"
                  :label="$t('lang.QText54')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText159'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form.toDoorCity"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    :loading="toDoorCityLoading"
                    ref="toDoorCity"
                  >
                    <el-option
                      v-for="item in toDoorCityList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  class="form-item"
                  prop="toDoorZipCode"
                  :label="$t('lang.QText55')"
                >
                  <el-input v-model="form.toDoorZipCode"> </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-line">
              <el-form-item
                class="form-item"
                :label="$t('lang.BText28')"
                prop="deliveryDate"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText107'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  v-model="form.deliveryDate"
                  type="date"
                  :placeholder="$t('lang.BText81')"
                  :picker-options="deliveryDayOptions"
                  @change="deliveryDayChange"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div class="form-item-title servicesTitle">
              {{ $t("lang.BText79") }}
            </div>
            <el-checkbox
              class="quotation-detials-checkbox"
              :label="$t('lang.QText67')"
              v-model="form.destinationDelivFlag"
              true-label="1"
              false-label="0"
              :disabled="form.toType == 0"
            ></el-checkbox>

            <el-checkbox
              class="quotation-detials-checkbox"
              :label="$t('lang.BText25')"
              v-model="form.destinationCustomsClearFlag"
              true-label="1"
              false-label="0"
            ></el-checkbox>
            <el-checkbox
              class="quotation-detials-checkbox"
              :label="$t('lang.QText69')"
              v-model="form.destinationBondFlag"
              true-label="1"
              false-label="0"
            ></el-checkbox>
            <el-form-item
              prop="destinationBondType"
              label=" "
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText110'),
                  trigger: ['blur', 'change'],
                },
              ]"
              class="bond-radio incotermsType"
              v-if="form.destinationBondFlag == 1"
            >
              <el-radio-group v-model="form.destinationBondType">
                <el-radio label="0">
                  {{ $t("lang.QText70") }}
                </el-radio>
                <el-radio label="1">
                  {{ $t("lang.QText71") }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-checkbox
              class="quotation-detials-checkbox"
              :label="$t('lang.QText63')"
              v-model="form.destinationWareFlag"
              true-label="1"
              false-label="0"
            ></el-checkbox>
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="destinationWareServices"
                v-if="form.destinationWareFlag == 1"
                :label="$t('lang.NText182')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText183'),
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-select
                  class="date-card-select"
                  v-model="form.destinationWareServices"
                  :placeholder="$t('lang.BText80')"
                >
                  <el-option
                    v-for="item in WarehouseList.destWarehouse"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.BText29") }}</div>
          <div class="ContainersTitle">* {{ $t("lang.BText82") }}</div>
          <el-form-item
            v-for="(domain, index) in containerDatas"
            :key="domain.containerSize"
            :rules="{
              required: true,
              message: $t('lang.NText95'),
              trigger: 'blur',
            }"
            class="weightItem"
          >
            <el-select
              class="weight-select"
              v-model="domain.containerSize"
              placeholder=""
              @change="weightSelectChange"
            >
              <el-option
                v-for="item in ContainerTypeList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input
              class="weightInput"
              v-model="domain.count"
              min="1"
              max="99"
              :maxlength="2"
              @input="domain.count = domain.count.replace(/[^\d]/g, '')"
            >
              <div slot="prefix" class="chenghao">X</div>
            </el-input>
            <div class="add" @click="Add(index)">
              <svg-icon icon-class="up"></svg-icon>
            </div>
            <div class="prep" @click="Prep(index)">
              <svg-icon icon-class="down"></svg-icon>
            </div>
            <el-button
              type="text"
              icon="el-icon-delete"
              @click.prevent="removeDomain(domain)"
              v-if="containerDatas.length > 1"
            ></el-button>
            <el-button
              v-if="
                index == containerDatas.length - 1 &&
                index < ContainerTypeList.length - 1
              "
              type="text"
              @click.prevent="addDomain"
              >{{ $t("lang.BText83") }}</el-button
            >
          </el-form-item>
        </div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText27") }}</div>
          <div class="form-line">
            <el-form-item class="form-item" prop="quotationMainType">
              <div class="form-item-title">{{ $t("lang.QText43") }}</div>
              <el-select
                class="date-card-select"
                v-model="form.cargoType"
                :placeholder="$t('lang.BText80')"
                ref="cargoType"
              >
                <el-option
                  v-for="item in CargoTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-line">
            <el-form-item class="form-item" prop="Commdity">
              <div class="form-item-title">{{ $t("lang.QText47") }}</div>
              <el-select
                class="date-card-select"
                v-model="form.commodityHscodes"
                :placeholder="$t('lang.BText80')"
                filterable
                ref="commodityHscodes"
              >
                <el-option
                  v-for="item in CommodityType"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <!-- <div v-if="PackageDetail">
            <div class="packingList">
              <div
                class="group"
                v-for="(group, index) in groupList"
                :key="index"
              >
                <div class="group-title">Group {{ index + 1 }}</div>
                <div class="group-main">
                  <div class="group-line">
                    <div class="group-item">
                      <div class="group-item-title">
                        {{ $t("lang.NText186") }}
                      </div>
                      <el-input
                        style="width: 300px; margin-right: 20px"
                        v-model="group.ponoName"
                      >
                      </el-input>
                    </div>
                    <div class="group-item">
                      <div class="group-item-title required">
                        {{ $t("lang.NText80") }} *
                      </div>
                      <div class="Dimensions-input unspin">
                        <el-input
                          class="width100"
                          v-model="group.poQty"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-select
                          class="weight-select"
                          v-model="group.poQtyUnit"
                          placeholder=""
                          style="width: 150px"
                        >
                          <el-option
                            v-for="item in CargoUnitList"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div
                      class="group-item unspin"
                      style="width: 170px"
                      v-if="group.poQtyUnit == 'Pallet'"
                    >
                      <div class="group-item-title required">
                        {{ $t("lang.BText85") }} *
                      </div>
                      <el-input
                        style="width: 100px"
                        v-model="group.poCartonQty"
                        type="number"
                        @keydown.native="limite"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="group-line">
                    <el-radio v-model="group.poDetailType" label="0">
                      {{ $t("lang.BText86") }}
                    </el-radio>
                    <el-radio v-model="group.poDetailType" label="1">
                      {{ $t("lang.BText87") }}
                    </el-radio>
                  </div>
                  <template v-if="group.poDetailType == 1">
                    <div class="group-line">
                      <div class="Lenght-title Lenght-title1">
                        {{ $t("lang.BText88") }}
                        <div class="QtyType">
                          (<span v-if="group.poQtyUnit == 0">{{
                            $t("lang.NText187")
                          }}</span
                          ><span v-else>{{ $t("lang.BText50") }}</span
                          >)
                        </div>
                      </div>
                      <div class="Lenght-title Lenght-title2">
                        {{ $t("lang.BText89") }}
                        <div class="QtyType">
                          (<span v-if="group.poQtyUnit == 0">{{
                            $t("lang.NText187")
                          }}</span
                          ><span v-else>{{ $t("lang.BText50") }}</span
                          >)
                        </div>
                      </div>
                      <div class="Lenght-title Lenght-title3">
                        {{ $t("lang.BText90") }}
                        <div class="QtyType">
                          (<span v-if="group.poQtyUnit == 0">{{
                            $t("lang.NText187")
                          }}</span
                          ><span v-else>{{ $t("lang.BText50") }}</span
                          >)
                        </div>
                      </div>
                      <div class="Lenght-title Lenght-title4">
                        {{ $t("lang.BText48") }}
                        <div class="QtyType">
                          (<span v-if="group.poQtyUnit == 0">{{
                            $t("lang.NText187")
                          }}</span
                          ><span v-else>{{ $t("lang.BText50") }}</span
                          >)
                        </div>
                      </div>
                    </div>
                    <div class="group-line">
                      <div class="Dimensions-input unspin">
                        <el-input
                          style="width: 80px"
                          v-model="group.poL"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-input
                          style="width: 80px"
                          v-model="group.poW"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-input
                          style="width: 80px"
                          v-model="group.poH"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-select
                          class="weight-select"
                          v-model="group.poLwhUnit"
                          placeholder=""
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in VolumeUnitList"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="Dimensions-input unspin">
                        <el-input
                          style="width: 80px"
                          v-model="group.poWeight"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-select
                          class="weight-select"
                          v-model="group.poWeightUnit"
                          placeholder=""
                          style="width: 150px"
                        >
                          <el-option
                            v-for="item in WeightUnitList"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </template>
                  <template v-if="group.poDetailType == 0">
                    <div class="group-line">
                      <div class="Lenght-title Lenght-title5">
                        {{ $t("lang.BText47") }}
                        <div class="QtyType">({{ group.item7 }})</div>
                      </div>
                      <div class="Lenght-title">
                        {{ $t("lang.BText48") }}
                        <div class="QtyType">({{ group.item7 }})</div>
                      </div>
                    </div>
                    <div class="group-line">
                      <div class="Dimensions-input unspin">
                        <el-input
                          style="width: 100px"
                          v-model="group.poVolume"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-select
                          class="weight-select"
                          v-model="group.poVolumeUnit"
                          placeholder=""
                          style="width: 150px"
                        >
                          <el-option
                            v-for="item in VolumeUnitList"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="Dimensions-input unspin">
                        <el-input
                          style="width: 100px"
                          v-model="group.poWeight"
                          type="number"
                          @keydown.native="limite"
                        ></el-input>
                        <el-select
                          class="weight-select"
                          v-model="group.poWeightUnit"
                          placeholder=""
                          style="width: 150px"
                        >
                          <el-option
                            v-for="item in WeightUnitList"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="delect" @click="delectGroup(index)">
                  <svg-icon icon-class="delete"></svg-icon>
                </div>
              </div>

              <div class="addButton" @click="addNewGroup()">
                {{ $t("lang.BText49") }}
              </div>
            </div>
          </div> -->
          <div class="form-line unspin">
            <el-form-item
              class="form-item"
              prop="totalWeight"
              :label="$t('lang.QText28')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText137'),
                },
                {
                  pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                  message: $t('lang.QText96'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                v-model="form.totalWeight"
                type="number"
                @keydown.native="limite2"
              >
                <template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form.totalWeightUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in WeightUnitList"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select></template
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-line unspin">
            <el-form-item
              class="form-item"
              prop="totalVolume"
              :label="$t('lang.QText29')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText136'),
                },
                {
                  pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                  message: $t('lang.QText96'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                type="number"
                v-model="form.totalVolume"
                maxlength="10"
                @keydown.native="limite3"
                ><template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form.totalVolumeUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in VolumeUnitListForTotal"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select
                ></template>
              </el-input>
            </el-form-item>
          </div>

          <div class="form-line unspin">
            <el-form-item
              class="form-item"
              prop="totalQuantity"
              :label="$t('lang.QText30')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText135'),
                },
                {
                  min: 1,
                  max: 10,
                  message: $t('lang.QText93'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                v-model="form.totalQuantity"
                type="number"
                maxlength="10"
                @keydown.native="limite"
              >
                <template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form.totalQuantitUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in CargoUnitListForTotal"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select></template
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div
          class="form-item-title"
          style="
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid #e6e6e6;
            margin-bottom: 10px;
          "
        >
          {{ $t("lang.BText52") }}
        </div>
        <el-checkbox-group v-model="form.dangerousGoodsType">
          <el-checkbox class="quotation-detials-checkbox" label="0">
            <svg-icon icon-class="batteries"></svg-icon>
            {{ $t("lang.QText32") }}</el-checkbox
          >
          <el-checkbox class="quotation-detials-checkbox" label="1">
            <svg-icon icon-class="HazardousMaterials"></svg-icon>
            {{ $t("lang.QText33") }}</el-checkbox
          >
          <el-checkbox class="quotation-detials-checkbox" label="2">
            <svg-icon icon-class="other"></svg-icon>
            {{ $t("lang.QText34") }}</el-checkbox
          >
        </el-checkbox-group>

        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText38") }}</div>
          <div class="main">
            <el-form-item
              class="form-item"
              prop="incotermsType"
              :label="$t('lang.QText39')"
            >
              <div class="radiobox">
                <div
                  class="option"
                  :class="{
                    optionFocus:
                      form.incotermsType !== '' && form.incotermsType == 0,
                  }"
                  @click="clickType(0)"
                >
                  {{ $t("lang.QText40") }}
                </div>
                <div
                  class="option"
                  :class="{
                    optionFocus:
                      form.incotermsType !== '' && form.incotermsType == 1,
                  }"
                  @click="clickType(1)"
                >
                  {{ $t("lang.QText41") }}
                </div>
              </div>
            </el-form-item>
            <el-form-item
              class="form-item radio Incoterms-radio incotermsType"
              prop="incotermsDataType"
              v-if="
                form.incotermsType !== '' &&
                (form.incotermsType == 0 || form.incotermsType == 1)
              "
              label=" "
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText98'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-radio-group v-model="form.incotermsDataType">
                <template v-if="form.incotermsType == 0">
                  <el-radio
                    :label="item.dictValue"
                    v-for="item in incotermsData.buyData"
                    :key="item.dictValue"
                  >
                    {{ item.dictLabel }}
                  </el-radio>
                </template>
                <template v-if="form.incotermsType == 1">
                  <el-radio
                    :label="item.dictValue"
                    v-for="item in incotermsData.sellerData"
                    :key="item.dictValue"
                  >
                    {{ item.dictLabel }}
                  </el-radio>
                </template>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>

        <!-- <div class="Edit-item-title">Remark</div>
        <div class="main">
          <el-form-item class="form-item" prop="item13">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Yo! Leave a message, or something..."
              v-model="form.item1"
            >
            </el-input>
          </el-form-item>
        </div> -->
      </el-form>
    </div>
    <div class="bottom-button">
      <el-button type="info" @click="Cancel()">{{
        $t("lang.QText79")
      }}</el-button>
      <el-button type="primary" @click="Ok()">{{
        $t("lang.QText80")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import dwTimeLine from "../../../components/dwTimeline/dwTimeLine.vue";
import utils from "../../../utils/util";
export default {
  components: { dwTimeLine },
  props: {
    quotationData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      recentlyData:[],
      recentlyDataDe:[],
      step: 4,
      stepList: [
        {
          name: "From - To",
          zh_name: "起始-到达",
        },
        {
          name: "Date",
          zh_name: "日期",
        },
        {
          name: "Cargo info",
          zh_name: "货物信息",
        },
        {
          name: "Details & services",
          zh_name: "详细信息 & 服务",
        },
      ],
      form: {
        dangerousGoodsType: [],
      },
      address: [
        { name: "CNSHA | Shanghai,China" },
        { name: "CNSHA | Shanghai,China" },
      ],
      rules: {},
      option: 0,
      containerDatas: [{}],
      ContainerTypeList: [],
      checkList: [],
      quotationType: [],
      choosequotationType: {},
      incotermsData: [],
      WarehouseList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      deliveryDayOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      WeightUnitList: [], //重量单位
      VolumeUnitList: [], //体积单位
      VolumeUnitListForTotal: [], //总计单位体积
      CargoUnitListForTotal: [], //总计计量单位
      CargoUnitList: [], //计货单位
      CargoTypeList: [], //货物类型下拉
      CurrentyList: [], //询价单币种
      templateFlag: 0,
      PackageDetail: false,
      countryList: [],
      fromDoorStateList: [],
      fromDoorCityList: [],
      toDoorStateList: [],
      toDoorCityList: [],
      originPickUpStateList: [],
      originPickUpCityList: [],
      destinationDelivStateList: [],
      destinationDelivCityList: [],
      fromDoorStateLoading: false,
      fromDoorCityLoading: false,
      toDoorStateLoading: false,
      toDoorCityLoading: false,
      originPickUpStateLoading: false,
      originPickUpCityLoading: false,
      destinationDelivStateLoading: false,
      destinationDelivCityLoading: false,
      CommodityType: [],
      selectLoading: false,
      quotationTypeObj: {},
      fromPortsList: [],
      toPortsList: [],
      userInfo: {},
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.init();
  },
  methods: {
    chooseRecently(val){
      var address=val.address?val.address:'';
      this.$set(this.form,'fromDoorAddress',address);
      this.$set(this.form,'fromDoorCountry',val.country);
      this.$set(this.form,'fromDoorState',val.state);
      this.$set(this.form,'fromDoorCity',val.city);
      this.$set(this.form,'fromDoorZipCode',val.zipCode);
      this.getStateList({ code: val.country },0);
      this.getCityList({ state: val.state, code: val.country },0);
    },
    chooseRecentlyDe(val){
      var address=val.address?val.address:'';
      this.$set(this.form,'toDoorAddress',address);
      this.$set(this.form,'toDoorCountry',val.country);
      this.$set(this.form,'toDoorState',val.state);
      this.$set(this.form,'toDoorCity',val.city);
      this.$set(this.form,'toDoorZipCode',val.zipCode);
      this.getStateList({ code: val.country },1);
      this.getCityList({ state: val.state, code: val.country },1);
    },
    limite(e) {
      let key = e.key;
      let aa = this.form.totalQuantity + "";
      if (key == "Backspace") {
        return true;
      }
      if (key === "e" || key === "." || aa.length > 9) {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    limite2(e) {
      let key = e.key;
      let aa = this.form.totalWeight + "";
      if (key == "Backspace") {
        return true;
      }
      if (
        key === "e" ||
        (aa.indexOf(".") > 0 && key === ".") ||
        aa.length > 10
      ) {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    limite3(e) {
      let key = e.key;
      let aa = this.form.totalVolume;
      if (
        key === "e" ||
        (aa.indexOf(".") > 0 && key === ".") ||
        aa.length > 10
      ) {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    changeFormType(params) {
      this.$set(this.form, "originPickUpFlag", params);
    },
    changeToType(params) {
      this.$set(this.form, "destinationDelivFlag", params);
    },
    init() {
      this.$session("countryList").get((value) => {
        this.countryList = value;
      });
      if (this.$refs["ruleForm"]) {
        this.$nextTick(function () {
          this.$refs["ruleForm"].clearValidate();
        });
      }
      if (this.quotationData) {
        this.form = JSON.parse(JSON.stringify(this.quotationData));

        if (typeof this.form.containerDatas == "string") {
          this.containerDatas = JSON.parse(this.form.containerDatas);
        } else {
          this.containerDatas = this.form.containerDatas;
        }
        if (
          this.form.dangerousGoodsType !== "" &&
          typeof this.form.dangerousGoodsType == "string"
        ) {
          this.form.dangerousGoodsType = JSON.parse(
            this.form.dangerousGoodsType
          );
        } else if (this.form.dangerousGoodsType == "") {
          this.form.dangerousGoodsType = [];
        }
        if (this.form.services && this.form.services.length > 0) {
          this.setServices(this.form.services);
        }
        this.findFormPostsList(this.form.fromCyPort);
        this.findToPortsList(this.form.toCyPort);
        this.getAllAddress();
        this.getBaseData();
        this.weightSelectChange();
      }
      if (this.form.quotationTemplateFlag == 1) {
        this.templateFlag = 1;
      }
      this.findaddressList();
      this.findaddressListDe();
    },
    findaddressList() {
      var queryForm={
        type:'20',
        addressType: '10',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyData=res.data.data||[]
        });
    },
    findaddressListDe() {
      var queryForm={
        type:'20',
        addressType: '20',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDataDe=res.data.data||[]
        });
    },
    clickType(pro) {
      this.$set(this.form, "incotermsType", pro);
    },
    // 获取服务数据
    setServices(e) {
      let data = e;
      data.forEach((item, index) => {
        switch (item.serviceType) {
          case "0":
            this.$set(this.form, "originPickUpFlag", "1");
            // this.$set(this.form, "originPickUpAddress", item.address);
            // this.$set(this.form, "originPickUpAddressId", item.addressId);
            // this.$set(this.form, "originPickUpCountry", item.country);
            // this.$set(this.form, "originPickUpCity", item.city);
            // this.$set(this.form, "originPickUpState", item.countryState);
            // this.$set(this.form, "originPickUpZipCode", item.zipCode);
            // this.$set(this.form, "originPickUpCountryName", item.countryName);
            // this.$set(this.form, "originPickUpCityName", item.cityName);
            this.$set(
              this.form,
              "originPickUpStateName",
              item.countryStateName
            );
            break;
          case "1":
            this.$set(this.form, "originCustomsClearFlag", "1");
            break;
          case "2":
            this.$set(this.form, "originInsurFlag", "1");
            this.$set(this.form, "originInsurVlaue", item.insurVlaue);
            this.$set(this.form, "originInsurCurr", item.insurCurr);
            break;
          case "3":
            this.$set(this.form, "originBondFlag", "1");
            this.$set(this.form, "originBondType", item.bondType);
            break;
          case "4":
            this.$set(this.form, "originIsfFlag", "1");
            break;
          case "5":
            this.$set(this.form, "originWareFlag", "1");
            this.$set(this.form, "originWareServices", item.warehouse);
            break;
          case "6":
            this.$set(this.form, "destinationDelivFlag", "1");
            break;
          case "7":
            this.$set(this.form, "destinationCustomsClearFlag", "1");
            break;
          case "8":
            this.$set(this.form, "destinationBondFlag", "1");
            this.$set(this.form, "destinationBondType", item.bondType);
            break;
          case "9":
            this.$set(this.form, "destinationWareFlag", "1");
            this.$set(this.form, "destinationWareServices", item.warehouse);
            break;
          case "10":
            this.$set(this.form, "originAmsFlag", "1");
            break;
          case "11":
            this.$set(this.form, "originAciFlag", "1");
            break;
          case "12":
            this.$set(this.form, "originDocFlag", "1");
            break;

          default:
            break;
        }
      });
    },
    // 获取基础数据
    getBaseData() {
      this.$session("CargoTypeList").get((value) => {
        this.CargoTypeList = value;
      });
      this.$session("ContainerTypeList").get((value) => {
        this.ContainerTypeList = value;
      });
      this.$session("WarehouseList").get((value) => {
        this.WarehouseList = value;
      });
      this.$session("incotermsData").get((value) => {
        this.incotermsData = value;
      });
      let that = this;
      that.$session("WeightUnitList").get((value) => {
        that.WeightUnitList = value;
        that.$set(that.form, "poWeightUnit", value[0].dictValue);
      });
      that.$session("VolumeUnitList").get((value) => {
        that.VolumeUnitList = value;
        that.$set(that.form, "poVolumeUnit", value[0].dictValue);
      });
      that.$session("CargoUnitList").get((value) => {
        that.CargoUnitList = value;
        that.$set(that.form, "poQtyUnit", value[0].dictValue);
      });
      this.$session("VolumeUnitListForTotal").get((value) => {
        that.VolumeUnitListForTotal = value;
        that.$set(that.form, "totalVolumeUnit", value[0].dictValue);
      });

      this.$session("CargoUnitListForTotal").get((value) => {
        that.CargoUnitListForTotal = value;
        that.$set(that.form, "totalQuantitUnit", value[0].dictValue);
      });
      that.$session("CommodityType").get((value) => {
        that.CommodityType = value;
      });
      this.$session("CurrentyList").get((value) => {
        that.CurrentyList = value;
      });
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
    },
    // 初始化地址下拉
    getAllAddress() {
      if (this.form.fromDoorCountry && this.form.fromDoorCountry !== "") {
        this.getStateList({ code: this.form.fromDoorCountry }, 0);
        let params = {
          state: this.form.fromDoorState,
          code: this.form.fromDoorCountry,
        };
        this.getCityList(params, 0);
      }
      if (this.form.toDoorCountry && this.form.toDoorCountry !== "") {
        this.getStateList({ code: this.form.toDoorCountry }, 1);
        let params = {
          state: this.form.toDoorState,
          code: this.form.toDoorCountry,
        };
        this.getCityList(params, 1);
      }
    },
    Cancel() {
      this.$emit("cancelEdit");
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    CargoReadyDayChange() {
      let that = this;
      this.deliveryDayOptions = {
        disabledDate(time) {
          if (that.form.cargoReadyDate) {
            return (
              time.getTime() < new Date(that.form.cargoReadyDate).getTime()
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    deliveryDayChange() {
      let that = this;
      this.pickerOptions = {
        disabledDate(time) {
          if (that.form.deliveryDate) {
            return (
              time.getTime() > new Date(that.form.deliveryDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    Save() {
      let that = this;
      that.$store.commit("api/SET_LOADING", true);
      let newDangerousGoodsType = [];
      //TODO 修改了校验和格式化
      JSON.parse(that.form.dangerousGoodsType).forEach((element, index) => {
        if (element) {
          newDangerousGoodsType.push(index);
        }
      });
      //TODO 序列化每次更新保存的时候，传值都变成了中文的双引号
      // console.log(newDangerousGoodsType);
      // console.log(JSON.stringify(newDangerousGoodsType));
      that.form.dangerousGoodsType = JSON.stringify(newDangerousGoodsType);
      that.form.quotationTemplateFlag = this.templateFlag;
      that.$ajax
        .cPost(that.source.quotation_api.saveQuotation, that.form)
        .then((res) => {
          if (res.data.result == "true") {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          }
          this.$store.commit("api/SET_LOADING", false);
          this.$router.push("/Quotation/quotation");
        });
    },
    addDomain() {
      this.containerDatas.push({
        count: "1",
        containerSize: "",
      });
    },
    removeDomain(data) {
      var index = this.containerDatas.indexOf(data);
      this.ContainerTypeList.forEach((item, index) => {
        if (item.dictValue == data.containerSize) {
          item.disabled = false;
        }
      });
      if (index !== -1) {
        this.containerDatas.splice(index, 1);
      }
    },
    handleChange(type) {
      this.form.quotationMainType = type[0];
      this.form.quotationType = type[1];
    },
    getStateList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          this.fromDoorStateLoading = false;
          this.toDoorStateLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.fromDoorStateList = res.data.data;
            } else if (num == 1) {
              this.toDoorStateList = res.data.data;
            } else if (num == 2) {
              this.originPickUpStateList = res.data.data;
            } else if (num == 3) {
              this.destinationDelivStateList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          this.fromDoorCityLoading = false;
          this.toDoorCityLoading = false;
          this.originPickUpCityLoading = false;
          this.destinationDelivCityLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.fromDoorCityList = res.data.data;
            } else if (num == 1) {
              this.toDoorCityList = res.data.data;
            } else if (num == 2) {
              this.originPickUpCityList = res.data.data;
            } else if (num == 3) {
              this.destinationDelivCityList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e, num) {
      if (num == 0) {
        this.$set(this.form, "fromDoorState", "");
        this.$set(this.form, "fromDoorCity", "");
      } else if (num == 1) {
        this.$set(this.form, "toDoorState", "");
        this.$set(this.form, "toDoorCity", "");
      } else if (num == 2) {
        this.$set(this.form, "originPickUpState", "");
        this.$set(this.form, "originPickUpCity", "");
      } else if (num == 3) {
        this.$set(this.form, "destinationDelivState", "");
        this.$set(this.form, "destinationDelivCity", "");
      }
      if (e) {
        this.fromDoorStateLoading = true;
        this.getStateList({ code: e }, num);
      }
    },

    stateChange(e, num) {
      if (num == 0) {
        this.$set(this.form, "fromDoorCity", "");
        if (e) {
          this.fromDoorCityLoading = true;
          this.getCityList({ state: e, code: this.form.fromDoorCountry }, num);
        }
      } else if (num == 1) {
        this.$set(this.form, "toDoorCity", "");
        if (e) {
          this.toDoorCityLoading = true;
          this.getCityList({ state: e, code: this.form.toDoorCountry }, num);
        }
      } else if (num == 2) {
        this.$set(this.form, "originPickUpCity", "");
        if (e) {
          this.originPickUpCityLoading = true;
          this.getCityList(
            { state: e, code: this.form.originPickUpCountry },
            num
          );
        }
      } else if (num == 3) {
        this.$set(this.form, "destinationDelivCity", "");
        if (e) {
          this.destinationDelivCityLoading = true;
          this.getCityList(
            { state: e, code: this.form.destinationdelivCountry },
            num
          );
        }
      }
    },
    toCountryChange(e) {
      this.form.state = "";
      if (e) {
        this.getStateList({ code: e }, 1);
      }
    },
    toStateChange(e) {
      if (e) {
        this.getCityList({ state: e, code: this.form.toDoorCountry }, 1);
      }
    },
    findFormPostsList(e) {
      this.findPortsList(e, 0);
    },
    findToPortsList(e) {
      this.findPortsList(e, 1);
    },
    findPortsList(e, num) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            if (num == 0) {
              this.fromPortsList = res.data.data;
            } else {
              this.toPortsList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    Ok() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.form.totalVolume = Number(that.form.totalVolume).toFixed(3);
          that.form.totalWeight = Number(that.form.totalWeight).toFixed(3);
          that.form.templateFlag = this.templateFlag;
          that.form.containerDatas = this.containerDatas;
          this.setLabel();
          this.$emit("editReview", {
            editTemplateFlag: this.templateFlag,
            submitForm: that.form,
          });
        }
      });
    },
    setLabel() {
      if (this.form.fromType == 0) {
        this.form.fromCyPortName = this.$refs.fromCyPort.selectedLabel;
      } else {
        this.form.fromDoorCountryName =
          this.$refs.fromDoorCountry.selectedLabel;
        this.form.fromDoorStateName = this.$refs.fromDoorState.selectedLabel;
        this.form.fromDoorCityName = this.$refs.fromDoorCity.selectedLabel;
      }
      if (this.form.toType == 0) {
        this.form.toCyPortName = this.$refs.toCyPort.selectedLabel;
      } else {
        this.form.toDoorCountryLabel = this.$refs.toDoorCountry.selectedLabel;
        this.form.toDoorStateName = this.$refs.toDoorState.selectedLabel;
        this.form.toDoorCityName = this.$refs.toDoorCity.selectedLabel;
      }
      this.form.cargoTypeName = this.$refs.cargoType.selectedLabel;
      this.form.commodityHscodesName =
        this.$refs.commodityHscodes.selectedLabel;
      // if (this.form.originPickUpFlag == 1) {
      //   this.form.originPickUpCountryName =
      //     this.$refs.originPickUpCountry.selectedLabel;
      //   this.form.originPickUpStateName =
      //     this.$refs.originPickUpState.selectedLabel;
      //   this.form.originPickUpCityName =
      //     this.$refs.originPickUpCity.selectedLabel;
      // }
      // if (this.form.destinationDelivFlag == 1) {
      //   this.form.destinationdelivCountryName =
      //     this.$refs.destinationdelivCountry.selectedLabel;
      //   this.form.destinationDelivStateName =
      //     this.$refs.destinationDelivState.selectedLabel;
      //   this.form.destinationDelivCityName =
      //     this.$refs.destinationDelivCity.selectedLabel;
      // }
    },
    //重量选择改变时改变不可选状态
    weightSelectChange(val) {
      this.ContainerTypeList.forEach((item) => {
        item.disabled = false;
        this.containerDatas.forEach((item2) => {
          if (item.dictValue == item2.containerSize) {
            item.disabled = true;
          }
        });
      });
    },
    Add(e) {
      this.containerDatas.forEach((item, index) => {
        if (e == index) {
          if (item.count < 99) {
            item.count++;
          }
        }
      });
    },
    Prep(e) {
      this.containerDatas.forEach((item, index) => {
        if (e == index) {
          if (item.count > 1) {
            item.count--;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.editList {
  width: 860px;
  margin: auto;
  text-align: left;
  overflow: hidden;
  padding-bottom: 100px;
  //   font-family: "REGULAR";
  .el-input__inner {
    height: 48px;
  }

  .el-input {
    height: 48px;
  }

  .el-form-item {
    display: flex;
    flex-direction: column;
  }
  .timeline {
    background-color: $background1;
    padding: 20px 30px;
    border: 1px solid $borderColor1;
  }
  .Edit-title {
    font-size: 60px;
    color: $mainColor;
    text-align: left;
    padding: 25px 30px;
    display: flex;
    // font-weight: bold;
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .dw-card2 {
    .title {
      margin-bottom: 10px;
    }
    .ContainersTitle {
      color: $mainColor;
    }
  }
  .Edit-main {
    padding: 0 30px;

    .Ocean-freight-FCL {
      border-top: 4px solid $mainColor;
      position: relative;
      height: 140px;
      .icon-div {
        position: absolute;
        top: 20px;
        left: 0;
        height: 120px;
        width: 240px;
        background: $mainColor;
        display: flex;
        align-items: center;
        .svg-icon {
          color: #fff;
          height: 60px;
          width: 60px;
          padding: 0 29px;
        }
      }
      .icon-line {
        width: 3px;
        height: 60px;
        background: #fff;
      }
      .title {
        position: absolute;
        left: 260px;
        right: 0;
        bottom: 0;
        font-size: 60px;
        color: $mainColor;
        border-bottom: 4px solid $mainColor;
        line-height: 68px;
      }
    }
    .Edit-item-title {
      border-bottom: 1px solid $fontColor1;
      margin-top: 30px;
      padding-bottom: 5px;
      font-size: 30px;
      color: #999999;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .date {
        font-size: 20px;
        line-height: 60px;
      }
    }
    .date-card-select {
      width: 100%;
    }

    .title48 {
      width: 48%;
    }

    .form-line {
      display: flex;
      justify-content: space-between;
      .form-item {
        width: 48%;
      }
    }

    .form-item-title {
      font-size: 20px;
      padding-left: 15px;
      color: $fontColor2;
    }

    .servicesTitle {
      margin-bottom: 10px;
    }
    .line-key {
      display: flex;
      flex: 3;
      .line-key-icon {
        width: 10px;
        height: 10px;
        background: $mainColor;
        margin-right: 10px;
        margin: 10px 10px 10px 0;
      }
    }
    .Edit-item-line {
      display: flex;
      justify-content: space-between;
    }
    .line-value {
      flex: 7;
    }
    .insurance-line {
      display: flex;
      .insurance-input {
        width: 250px;
        margin-right: 30px;
      }
      .insurance-select {
        width: 135px;
      }
    }
    .bond-radio {
      display: flex;
      margin-bottom: 30px;
    }
    .weightItem {
      .el-form-item__content {
        display: flex;
        .weight-select {
          width: 200px;
        }
        .weightInput {
          width: 140px;
          margin-left: -1px;
          margin-right: 20px;
          .chenghao {
            line-height: 48px;
            font-size: 20px;
            padding-left: 5px;
            color: $mainColor;
          }
        }
      }
      .add {
        position: absolute;
        left: 309px;
        top: 0;
        width: 30px;
        height: 24px;
        background-color: $borderColor1;
        .svg-icon {
          position: absolute;
          height: 10px;
          left: 8px;
          top: 5px;
          line-height: 10px;
          fill: $fontColor1;
          stroke: $fontColor1;
        }
      }
      .add:hover,
      .prep:hover {
        background-color: $fontColor3;
      }
      .prep {
        position: absolute;
        left: 309px;
        top: 24px;
        width: 30px;
        height: 24px;
        background-color: $borderColor1;
        .svg-icon {
          position: absolute;
          height: 25px;
          left: 8px;
          top: 2px;
          line-height: 10px;
          fill: $fontColor1;
          stroke: $fontColor1;
        }
      }
    }
    .Package-details {
      display: flex;
      .add {
      }
    }

    .quotation-detials-checkbox {
      display: flex;
      .el-checkbox__label {
        font-size: 20px !important;
        color: $mainColor;
        padding: 10px 20px;
        .svg-icon {
          margin-top: -10px;
          // fill: $mainColor;
          // stroke: $mainColor;
        }
      }
    }
  }
  .packingList {
    background-color: $background1;
    padding: 20px;
    .group {
      padding-bottom: 20px;
      border-bottom: 1px solid $borderColor1;
      padding-top: 10px;
    }
    .group-title {
      color: $fontColor2;
      margin-bottom: 20px;
    }
    .group-main {
      .group-line {
        display: flex;
        margin: 15px 0;
        .Dimensions-input {
          display: flex;
          margin-right: 20px;
        }
        .Lenght-title {
          display: flex;
          line-height: 30px;
        }
        .Lenght-title1,
        .Lenght-title2 {
          width: 90px;
        }
        .Lenght-title3 {
          width: 220px;
        }
        .Lenght-title5 {
          width: 300px;
        }
        .QtyType {
          font-size: 14px;
          margin: 0 5px;
        }
        .group-item {
          .group-item-title {
            color: $fontColor2;
            margin-bottom: 10px;
          }

          .width100 {
            width: 100px;
          }
        }
      }
    }

    .delect {
      color: $background2;
      border: 1px solid $borderColor1;
      width: 47px;
      line-height: 36px;
      padding-bottom: 10px;
      text-align: center;
      background-color: #fff;
      margin-top: -65px;
      float: right;
    }
    .addButton {
      height: 48px;
      width: 180px;
      line-height: 48px;
      color: $background2;
      border: 1px solid $borderColor1;
      margin-top: 20px;
      text-align: center;
      background-color: #fff;
      font-size: 20px;
      font-family: "montserratLight";
      cursor: pointer;
    }
  }
  .Unit-select {
    width: 120px;
  }

  .el-cascader {
    width: 100%;
  }

  .bottom-button {
    display: flex;
    justify-content: end;
    padding: 60px 0;
  }
  .el-checkbox-group,
  .OriginServices-checkbox {
    display: flex;
    flex-direction: column;
  }
  .incotermsType {
    display: flex;
    flex-direction: row;
  }
}
</style>