<template>
  <div class="detailDialog" v-loading="loading">
    <div class="booking-left-menu">
      <div
        class="booking-button button"
        :class="{ 'is-check': type == 1 }"
        @click="type = '1'"
        v-if="stateView == ''"
      >
        {{ $t("lang.NText83") }}
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': type == 3 }"
        @click="type = '3'"
        v-if="stateView == '' || stateView == 'RP'"
      >
        <span v-if="stateView == ''">{{ $t("lang.QText85") }}</span>
        <span v-else-if="stateView == 'RP'">{{ $t("lang.NText84") }}</span>
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': type == 2 }"
        @click="type = '2'"
        v-if="stateView == '' || stateView == 'RP'"
      >
        {{ $t("lang.QText86") }}
      </div>

      <!-- <div
        class="chatHistory-button button"
        :class="{ 'is-check': type == 4 }"
        @click="type = '4'"
      >
        Chat history
      </div> -->
      <div
        class="chatHistory-button button"
        :class="{ 'is-check': type == 5 }"
        @click="type = '5'"
        v-if="stateView == ''"
      >
        {{ $t("lang.NText303") }}
      </div>
    </div>
    <div class="TaskLog-title" v-if="type == '1' && stepsData.head">
      <div class="taskType">Q</div>
      <div
        class="TaskLog-title-ID"
        v-clipboard:copy="stepsData.head ? stepsData.head.taskID : '测试'"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <span>{{ $t("lang.BText63") }}：</span>
        <span style="color: #299be4">{{
          stepsData.head ? stepsData.head.taskID : "测试"
        }}</span
        ><svg-icon icon-class="copy"></svg-icon>
      </div>
    </div>
    <div
      class="TaskLog-title"
      v-if="(type == '2' || type == '3') && stateView == ''"
    >
      <div class="taskType">Q</div>
      <div
        class="TaskLog-title-ID"
        v-clipboard:copy="form.quotationNo"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-if="form.quotationNo"
      >
        <span>{{ $t("lang.QText82") }}：</span>
        <span style="color: #299be4">{{ form.quotationNo }}</span
        ><svg-icon icon-class="copy"></svg-icon>
      </div>
      <!-- 已提交和等待生成报价 -->
      <div
        class="statusForhtml"
        v-if="form.statusForhtml == 'Quote' || form.statusForhtml == 'Submit'"
      >
        {{ form.statusForhtml }}
      </div>
      <!-- 已经确认报价 -->
      <div
        class="statusForhtml"
        v-else-if="form.amountConfirmFlag == 1 && form.state == 20"
      >
        {{ form.statusForhtml }}
      </div>
      <!-- 生成报价等待确认 -->
      <el-button
        class="shippingAuthorization"
        @click="confirmQuotationCharges"
        v-else-if="form.amountConfirmFlag != 1 && form.state == 20"
        :loading="confirmQuotationChargesLoading"
      >
        <div class="text">{{ $t("lang.BText65") }}</div>
      </el-button>
      <div
        class="upload"
        @click="downloadPdf()"
        v-show="form.state == 20 && type == 2"
      >
        <i class="el-icon-download"></i>
      </div>
    </div>
    <div
      class="sharing"
      v-if="(type == '2' || type == '3') && stateView == 'RP'"
    >
      <div class="sharing-title">{{ $t("lang.NText304") }}</div>
      <div class="sharing-button">
        <el-button
          type="primary"
          @click="Accept"
          :disabled="form.shareFlag == '1' || form.shareFlag == '0'"
          >{{ $t("lang.NText49") }}</el-button
        >
        <el-button
          type="info"
          @click="Reject"
          :disabled="form.shareFlag == '1' || form.shareFlag == '0'"
          >{{ $t("lang.NText86") }}</el-button
        >
      </div>
    </div>
    <div class="dialog-detail">
      <template v-if="type == '2' && !form.headAmounts">
        <div class="kDialog-main">
          <div class="el-upload__text">{{ $t("lang.QText83") }}</div>
          <div class="offlineFile-line1">{{ $t("lang.BText72") }}</div>
          <el-upload
            :action="baseUrl + '/crm/edoc/upload'"
            class="uploaddiv"
            multiple
            :limit="3"
            :on-change="changeFile"
            :data="uploadData"
            :show-file-list="false"
          >
            <div class="upload">
              <dw-button type="primarySmall">{{
                $t("lang.BText73")
              }}</dw-button>
              <div class="file-type">{{ $t("lang.BText74") }}</div>
            </div>
          </el-upload>
          <div class="offlineFile" v-if="tableDatamy.length > 0">
            <div class="fileItem">
              <div class="fileName">
                {{ tableDatamy[0].documentName }}.{{
                  tableDatamy[0].documentType
                }}
              </div>
              <svg-icon icon-class="delete" @click="delectFile()"></svg-icon>
            </div>
          </div>
          <!-- <div class="fileSearch">
            <el-input placeholder="Document name"></el-input>
            <svg-icon icon-class="delete"></svg-icon>
          </div> -->
        </div>
      </template>
      <dw-steps
        v-if="type == '1'"
        :stepsData="stepsData"
        class="dwSteps"
      ></dw-steps>
      <div
        class="quotationMain"
        v-if="type == '2' && form.charges && form.charges.length > 0"
      >
        <quotation-view
          v-if="form.charges.length > 0"
          :quotationData="quotationData"
        ></quotation-view>
      </div>
      <div v-if="type == '3'">
        <review
          :quotationData="JSON.stringify(form)"
          :showButton="false"
        ></review>
      </div>
      <div v-if="type == '5'" class="edocsTab">
        <el-tabs v-model="eDocactiveName" @tab-click="eDochandleClick">
          <el-tab-pane :label="$t('lang.NText7')" name="0"> </el-tab-pane>
          <el-tab-pane :label="$t('lang.QText86')" name="1"></el-tab-pane>
          <el-tab-pane
            disabled
            :label="$t('lang.BText35')"
            name="2"
          ></el-tab-pane>
          <el-tab-pane
            disabled
            :label="$t('lang.NText305')"
            name="3"
          ></el-tab-pane>
          <el-tab-pane
            disabled
            :label="$t('lang.NText306')"
            name="4"
          ></el-tab-pane>
          <el-tab-pane
            disabled
            :label="$t('lang.NText307')"
            name="5"
          ></el-tab-pane>
        </el-tabs>
        <p class="title" style="border-top: 1px solid #ccc">
          {{ $t("lang.NText308") }}
        </p>
        <el-table
          v-loading="jiazai"
          class="table"
          :data="tableDatamy"
          height="200px"
          :show-header="status"
          style="width: 100%"
        >
          <el-table-column :label="$t('lang.NText310')" width="300">
            <template slot-scope="scope">
              <div style="display: flex">
                <i class="el-icon-document"></i>
                <div>
                  <p class="name">
                    {{ scope.row.documentName }}.{{ scope.row.documentType }}
                  </p>
                  <p class="quo" v-if="scope.row.businessType == '1'">
                    {{ $t("lang.QText86") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '2'">
                    {{ $t("lang.BText35") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '3'">
                    {{ $t("lang.NText305") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '4'">
                    {{ $t("lang.NText306") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '5'">
                    {{ $t("lang.NText307") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '0'">
                    {{ $t("lang.NText7") }}
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" :label="$t('lang.NText9')">
          </el-table-column>
          <el-table-column width="60px">
            <template slot-scope="scope">
              <i class="el-icon-download" @click="downloadEdoc(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="pt12 pb12"
          style="width: 100%; float: left; margin-bottom: 35px"
        >
          <el-pagination
            style="border: none; margin-top: 0; padding-top: 0"
            @size-change="handleSizeChangemy"
            @current-change="handleCurrentChangemy"
            :current-page.sync="currentPagemy"
            :page-sizes="[5, 10, 15, 20, 25]"
            :page-size="pageSizemy"
            layout="total, sizes, prev, pager, next"
            :total="totalmy"
          >
          </el-pagination>
        </div>
        <p class="title">{{ $t("lang.NText309") }}</p>
        <el-table
          v-loading="jiazai"
          class="table"
          :data="tableDatasys"
          height="200px"
          :show-header="status"
          style="width: 100%"
        >
          <el-table-column :label="$t('lang.NText310')" width="300">
            <template slot-scope="scope">
              <div style="display: flex">
                <i class="el-icon-document"></i>
                <div>
                  <p class="name">
                    {{ scope.row.documentName }}.{{ scope.row.documentType }}
                  </p>
                  <p class="quo" v-if="scope.row.businessType == '1'">
                    {{ $t("lang.QText86") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '2'">
                    {{ $t("lang.BText35") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '3'">
                    {{ $t("lang.NText305") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '4'">
                    {{ $t("lang.NText306") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '5'">
                    {{ $t("lang.NText307") }}
                  </p>
                  <p class="quo" v-else-if="scope.row.businessType == '0'">
                    {{ $t("lang.NText7") }}
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lang.NText311')">
            <template slot-scope="scope">
              <p
                class="quo"
                v-if="scope.row.documentStates == '1'"
                style="color: #299be4"
              >
                {{ $t("lang.NText312") }}
              </p>
              <p class="quo" v-else-if="scope.row.documentStates == '0'">
                {{ $t("lang.NText313") }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" :label="$t('lang.BText5')">
          </el-table-column>
          <el-table-column width="60px">
            <template slot-scope="scope">
              <i class="el-icon-download" @click="downloadEdoc(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="pt12 pb12"
          style="width: 100%; float: left; margin-bottom: 10px"
        >
          <el-pagination
            style="border: none; margin-top: 0; padding-top: 0"
            @size-change="handleSizeChangesys"
            @current-change="handleCurrentChangesys"
            :current-page.sync="currentPagesys"
            :page-sizes="[5, 10, 15, 20, 25]"
            :page-size="pageSizesys"
            layout="total, sizes, prev, pager, next"
            :total="totalsys"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <pdf
      v-for="i in numPages"
      :key="i"
      :src="pdfSrc"
      :page="i"
      ref="myPdfComponent"
    ></pdf> -->
  </div>
</template>

<script>
import test from "../../../test/test";
import dwSteps from "../../../components/dwSteps/dwSteps.vue";
import bookingview from "../../Booking/review.vue";
import FillInList from "../../Booking/fillInList.vue";
import KSecondHeader from "../../../components/kHeader/kSecondHeader.vue";
import basicInfo from "../../Shipment/basicInfo.vue";
import routing from "../../Shipment/routing.vue";
import container from "../../Shipment/container.vue";
import eDoc from "../../Shipment/eDoc.vue";
import QuotationView from "../quotationView.vue";
import Template from "../../Booking/template.vue";
import review from "../aQuote/Review.vue";
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import global from "../../../utils/global";

export default {
  components: {
    dwSteps,
    bookingview,
    FillInList,
    KSecondHeader,
    basicInfo,
    routing,
    container,
    eDoc,
    QuotationView,
    review,
    pdf,
  },
  props: {
    iShipment: {
      type: Boolean,
      default: false,
    },
    stateView: {
      type: String,
      default: "",
    },
    rejectUrl: {
      type: String,
      default: "",
    },
    acceptUrl: {
      type: String,
      default: "",
    },
    bookingId: {
      type: String,
      default: "",
    },
    quotationData: {
      type: String,
      default: "",
    },
    detailDialogType: {
      type: String,
      default: "2",
    },
    reviewUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jiazai: false,
      status: false,
      tableDatamy: [],
      tableDatasys: [],
      eDocactiveName: "0",
      pageSizesys: 5,
      pageNosys: 1,
      totalsys: 0,
      currentPagesys: 1,
      pageSizemy: 5,
      pageNomy: 1,
      totalmy: 0,
      currentPagemy: 1,
      userinfo: {},
      handleSelectStr: "0",
      loading: false,
      quotationList: test.QuotationData,
      showFillIn: false,
      hasQuotation: false,
      TrackClickArrState: false,
      state: false,
      headFile: null,
      shipmentMenu: [
        {
          menuName: "Basic info",
          link: "/Shipment/basicInfo",
        },
        {
          menuName: "Routing",
          link: "/Shipment/Routing",
        },
        {
          menuName: "Container",
          link: "/Shipment/Container",
        },
        {
          menuName: "eDoc",
          link: "/Shipment/eDoc",
        },
      ],
      shipmentTitle: "",
      shipmentView: {},
      TrackClickArr: [],
      type: "2",
      form: {},
      quotationTypeObj: {},
      stepsData: {},
      uploadData: {},
      indexE: "0",
      pdfSrc: "",
      numPages: null,
      file: null,
      confirmQuotationChargesLoading: false,
      baseUrl: null,
    };
  },
  created() {
    this.baseUrl = global.baseUrl;
  },
  mounted: function () {
    this.init();
  },
  methods: {
    downloadEdoc(row) {
      this.$ajax
        .cPost(this.source.quotation_api.downloadedoc, {
          id: row.id,
        })
        .then((res) => {
          this.loading = false;
          console.log(res.data);
          let da = res.data.data;
          let a = document.createElement("a");
          a.href = da.filePath;
          a.download = da.fileName;
          window.open(a, "_blank");
          // a.click();
        });
    },
    eDochandleClick(val) {
      console.log(val.name);
      this.indexE = val.name;
      this.pageNomy = 1;
      this.currentPagemy = 1;
      this.pageNosys = 1;
      this.currentPagesys = 1;
      this.initData(val.name);
    },
    handleSizeChangemy(val) {
      this.pageSizemy = val;
      this.initData(this.indexE);
    },
    handleCurrentChangemy(val) {
      this.pageNomy = val;
      this.initData(this.indexE);
    },
    handleSizeChangesys(val) {
      this.pageSizesys = val;
      this.initData(this.indexE);
    },
    handleCurrentChangesys(val) {
      this.pageNosys = val;
      this.initData(this.indexE);
    },
    initData(e) {
      let that = this;
      that.jiazai = true;
      let datasys = {
        createBy: "system",
        businessType: e,
        pageSize: that.pageSizesys,
        pageNo: that.pageNosys,
        qid: that.form.id,
      };
      that.$ajax
        .cPost(that.source.quotation_api.edoclist, datasys)
        .then((res) => {
          that.jiazai = false;
          if (res.data.result && res.data.result == "true") {
            if (res.data.list) {
              that.tableDatasys = res.data.list;
              that.totalsys = res.data.allCount;
            } else {
              that.tableDatasys = [];
            }
          } else {
            that.tableDatasys = [];
          }
        });
      let datamy = {
        createBy: that.userinfo.loginCode,
        businessType: e,
        pageSize: that.pageSizemy,
        pageNo: that.pageNomy,
        qid: that.form.id,
      };
      that.$ajax
        .cPost(that.source.quotation_api.edoclist, datamy)
        .then((res) => {
          that.jiazai = false;
          if (res.data.result && res.data.result == "true") {
            if (res.data.list) {
              that.tableDatamy = res.data.list;
              that.totalmy = res.data.allCount;
            } else {
              that.tableDatamy = [];
            }
          } else {
            that.tableDatamy = [];
          }
        });
    },
    init() {
      this.type = this.detailDialogType;
      console.log(this.detailDialogType);
      if (this.quotationData) {
        this.form = JSON.parse(this.quotationData);
        this.stepsData = this.form.taskLogs;
        console.log(this.form);
      }
      if (this.form.services && this.form.services.length > 0) {
        this.setServices(this.form.services);
      }
      if (typeof this.form.containerDatas == "string") {
        this.form.containerDatas = JSON.parse(this.form.containerDatas);
      }
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });

      this.$session("userInfo").get((value) => {
        this.userinfo = value;
        this.uploadData = {
          createBy: value.email,
          businessType: "1",
          qid: this.form.id,
        };
      });
      this.initData("0");
    },

    getShipmentData() {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.shipment_api.view, {
          shipmentNo: this.shipmentData.shipmentNo,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.shipmentView = res.data;
            this.shipmentView.shipmentId = this.shipmentData.shipmentId;
            this.shipmentView.consoleId = this.shipmentData.consoleId;
            this.$store.commit("api/SET_SHIPMENT", this.shipmentView);
            if (this.type == "3") this.TrackClick();
          }
          this.$store.commit("api/SET_LOADING", false);
        });
    },
    clickEdit() {},
    onCopy() {
      this.$notify({
        title: this.$t("lang.SText125"),
        type: "success",
        offset: 100,
      });
    },
    onError() {},
    upLoad(file, fileList) {
      this.headFile = file;
      console.log(file);
      this.hasQuotation = true;
    },
    handleSelect(e) {
      this.handleSelectStr = e;
      this.shipmentTitle = this.shipmentMenu[e].menuName;
      return;
      this.$router.push({ path: this.shipmentMenu[e].link });
    },
    TrackClick() {
      this.$ajax
        .cPost(this.source.tracking_api.queryTracking, {
          selectNo: this.shipmentData.shipmentNo,
        })
        .then((res) => {
          if (res.data.result == "true") {
            if (
              res.data.tracking[0]["trackingSub"][0]["containerNumberListMap"]
                .length
            ) {
              this.TrackClickArrState = true;
              sessionStorage.setItem(
                "TrackClickArr",
                JSON.stringify(
                  res.data.tracking[0]["trackingSub"][0][
                    "containerNumberListMap"
                  ]
                )
              );
            } else {
              this.TrackClickArrState = false;
            }
            this.state = true;
            this.loading = false;
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    confirmQuotationCharges() {
      this.confirmQuotationChargesLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.confirmQuotationCharges, {
          quotationId: this.form.id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.form.amountConfirmFlag = 1;
            this.form.statusForhtml = "Confirm";
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
          this.$emit("confirmQuotationCharges");
          this.confirmQuotationChargesLoading = false;
        });
    },
    // 获取服务数据
    setServices(e) {
      let data = e;
      data.forEach((item, index) => {
        switch (item.serviceType) {
          case "0":
            this.form.originPickUpFlag = "1";
            // this.form.originPickUpAddress = item.address;
            // this.form.originPickUpAddressId = item.addressId;
            // this.form.originPickUpCountry = item.country;
            // this.form.originPickUpCity = item.city;
            // this.form.originPickUpState = item.countryState;
            // this.form.originPickUpZipCode = item.zipCode;
            // this.form.originPickUpCountryName = item.countryName;
            // this.form.originPickUpCityName = item.cityName;
            // this.form.originPickUpStateName = item.countryStateName;
            break;
          case "1":
            this.form.originCustomsClearFlag = "1";
            break;
          case "2":
            this.form.originInsurFlag = "1";
            this.form.originInsurVlaue = item.insurVlaue;
            this.form.originInsurCurr = item.insurCurr;
            break;
          case "3":
            this.form.originBondFlag = "1";
            this.form.originBondType = item.bondType;
            break;
          case "4":
            this.form.originIsfFlag = "1";
            break;
          case "5":
            this.form.originWareFlag = "1";
            this.form.originWareServices = item.warehouse;
            break;
          case "6":
            this.form.destinationDelivFlag = "1";
            // this.form.destinationDelivAddressId = item.address;
            // this.form.destinationDelivAddressId = item.addressId;
            // this.form.destinationDelivCountry = item.country;
            // this.form.destinationDelivCity = item.city;
            // this.form.destinationDelivState = item.countryState;
            // this.form.destinationDelivCountryName = item.countryName;
            // this.form.destinationDelivCityName = item.cityName;
            // this.form.destinationDelivStateName = item.countryStateName;
            // this.form.destinationDelivZipCode = item.zipCode;
            break;
          case "7":
            this.form.destinationCustomsClearFlag = "1";
            break;
          case "8":
            this.form.destinationBondFlag = "1";
            this.form.destinationBondType = item.bondType;
            break;
          case "9":
            this.form.destinationWareFlag = "1";
            this.form.destinationWareServices = item.warehouse;
            break;
          case "10":
            this.form.originAmsFlag = "1";
            break;
          case "11":
            this.form.originAciFlag = "1";
            break;
          case "12":
            this.$set(this.form, "originDocFlag", "1");
            break;

          default:
            break;
        }
      });
    },
    Accept() {
      this.loading = true;
      let url = this.acceptUrl.split("de-well.com")[1];
      this.$ajax.cGet(url, {}).then((res) => {
        this.loading = false;
        if (res.data.result == "true") {
          this.$notify({
            title: this.$t("lang.NText323"),
            message: res.data.message,
            duration: 3000,
            type: "success",
          });
          var url = this.reviewUrl.split("com")[1];
          this.$ajax.cGet(url, {}).then((res) => {
            this.$set(this, "quotationData", JSON.stringify(res.data.data));
            this.init();
          });
        } else {
          this.$notify.error({
            title: this.$t("lang.NText324"),
            message: res.data.message,
            duration: 3000,
          });
        }
      });
    },
    Reject() {
      this.loading = true;
      let url = this.rejectUrl.split("de-well.com")[1];
      this.$ajax.cGet(url, {}).then((res) => {
        this.loading = false;
        if (res.data.result == "true") {
          this.$notify({
            title: this.$t("lang.NText323"),
            message: res.data.message,
            duration: 3000,
            type: "success",
          });
          var url = this.reviewUrl.split("com")[1];
          this.$ajax.cGet(url, {}).then((res) => {
            this.$set(this, "quotationData", JSON.stringify(res.data.data));
            this.init();
          });
        } else {
          this.$notify.error({
            title: this.$t("lang.NText324"),
            message: res.data.message,
            duration: 3000,
          });
        }
      });
    },
    // Accept() {
    //   this.loading = true;
    //   this.$ajax
    //     .cPost(this.source.quotation_api.comfirmShareQuotation, {
    //       quotationId: this.form.id,
    //       confirmFlag: true,
    //     })
    //     .then((res) => {
    //       if (res.data.result == "true") {
    //         this.$notify({
    //           title: res.data.message,
    //           type: "success",
    //           offset: 100,
    //         });
    //       }
    //       this.loading = false;
    //     });
    // },
    // Reject() {
    //   this.loading = true;
    //   this.$ajax
    //     .cPost(this.source.quotation_api.comfirmShareQuotation, {
    //       quotationId: this.form.id,
    //       confirmFlag: false,
    //     })
    //     .then((res) => {
    //       if (res.data.result == "true") {
    //         this.$notify({
    //           title: res.data.message,
    //           type: "success",
    //           offset: 100,
    //         });
    //       }
    //       this.loading = false;
    //     });
    // },
    downloadPdf() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.quotation_api.downloadPdf, {
          qid: this.form.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.result == "true") {
            let da = res.data.data;
            let a = document.createElement("a");
            a.href = da.filePath;
            a.download = da.fileName;
            document.body.appendChild(a);
            window.open(a, "_blank");
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    changeFile(file, fileList) {
      this.initData("0");
    },
    delectFile() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.quotation_api.deleteFile, {
          id: this.tableDatamy[0].id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          }
          this.initData("0");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.detailDialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__close {
    display: none;
  }
  .booking-left-menu {
    position: fixed;
    top: 200px;
    left: 15%;
    margin-left: -44px;
    .button {
      width: 146px;
      height: 60px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      font-size: 20px;
      border: 2px solid $mainColor;
      line-height: 60px;
      background-color: #fff;
      color: $mainColor;
      cursor: pointer;
    }
    .booking-button {
      margin-top: 30px;
    }
    .quotation-button {
      margin-top: 100px;
    }
    .chatHistory-button {
      margin-top: 100px;
    }
    .button:hover,
    .is-check {
      background-color: $mainColor;
      color: $background2;
    }
  }
  .TaskLog-title {
    text-align: left;
    // border-bottom: 2px solid $mainColor;
    margin: auto;
    display: flex;
    padding: 30px 0 10px 0;
    font-size: 30px;
    width: 1020px;

    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 50px;
      width: 630px;
      .svg-icon {
        font-size: 20px;
        color: $fontColor1;
        padding-left: 30px;
        margin-top: -10px;
      }
    }
    .statusForhtml {
      height: 50px;
      width: 200px;
      color: $mainColor;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
    }
    .shippingAuthorization {
      height: 50px;
      width: 200px;
      line-height: 50px;
      padding: 0;
      border-radius: 0;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
    }

    .shippingAuthorization:hover {
      background-color: $background2;
    }
    .upload {
      height: 46px;
      width: 46px;
      border: 2px solid $background2;
      margin-left: 20px;
      .el-icon-download {
        height: 16px;
        width: 16px;
        padding: 8px;
        color: $background2;
      }
    }
    .upload:hover {
      background-color: $background2;
      .el-icon-download {
        color: #fff;
      }
    }
  }
  .sharing {
    padding: 0;
    width: 1020px;
    text-align: left;
    margin: auto;
    padding: 30px 0 10px 0;
    font-size: 30px;
    width: 1020px;
    .sharing-title {
      border-bottom: 2px solid $mainColor;
      color: $mainColor;
      line-height: 45px;
    }
    .sharing-button {
      text-align: center;
      border-bottom: 2px solid $mainColor;
      padding: 50px;
    }
  }
  .shipment-title {
    text-align: left;
    width: 1120px;
    margin: auto;
    display: flex;
    height: 59px;
    font-size: 30px;
    width: 860px;
    border-bottom: 4px solid $mainColor;
    margin-top: 40px;
    justify-content: space-between;
    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 50px;
      display: flex;
    }
    .svg-icon {
      font-size: 20px;
      color: $fontColor1;
      padding-left: 30px;
      padding-top: 10px;
    }
    .shipmentHeader {
      .el-menu .is-active {
        height: 60px !important;
      }
      .el-menu .el-menu-item {
        height: 60px !important;
      }
    }
  }

  .dialog-detail {
    min-height: 850px;
    padding-bottom: 50px;
    width: 900px;
    margin: auto;
    .dwSteps {
      margin-top: 70px;
      width: 100%;
    }
    .quotationTitle {
      padding-top: 20px;
    }
    .edocsTab {
      .el-tabs__item {
        font-size: 18px;
      }
      .el-tabs {
        margin-top: 10px;
      }
      p.title {
        padding-top: 12px;
        color: $mainColor;
        font-size: 22px;
        font-weight: bold;
        border-bottom: 2px solid $mainColor;
        margin: 0;
        text-align: left;
        padding-bottom: 10px;
      }
      .el-tabs__item.is-active,
      .el-tabs__item:hover {
        color: $mainColor;
      }
      .el-tabs__nav-wrap::after {
        background: transparent;
      }
      .el-tabs__active-bar {
        background-color: $mainColor;
      }
      .table::before {
        height: 0;
      }
      .table {
        border: none;

        .el-icon-document {
          font-size: 30px;
          margin-right: 6px;
          color: $mainColor;
        }
        td.el-table__cell {
          border-bottom: none;
        }
        .el-table--fit {
          border-right: none;
        }
        .el-icon-download {
          font-size: 30px;
          margin-right: 6px;
          color: #299be4;
          border: 1px solid #299be4;
        }
        color: #606266 !important;
        .name {
          margin: 0;
          color: $mainColor;
        }
        .quo {
          margin: 0;
        }
      }
    }
    .bookingReviewMainTitle {
      width: 860px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      border-bottom: 4px solid $mainColor;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
    }

    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
    .el-upload__text {
      font-size: 40px;
      color: $fontColor2;
      text-align: center;
      padding: 150px 0;
      border-bottom: 2px solid $mainColor;
    }
    .offlineFile-line1 {
      font-size: 30px;
      text-align: left;
      margin-top: 30px;
      margin-left: 150px;
      color: $mainColor;
      padding: 30px 0;
    }
    .uploaddiv {
      margin-left: 150px;
    }
    .el-upload {
      display: inline;
    }
    .upload {
      display: flex;
      justify-content: start;
      margin-bottom: 60px;
      .file-type {
        font-size: 14px;
        margin-left: 40px;
        line-height: 48px;
        color: #9b9b9b;
      }
    }
    .offlineFile {
      display: flex;
      justify-content: space-between;
      margin-left: 150px;

      .fileItem {
        display: flex;
        color: $mainColor;
        line-height: 30px;
        .svg-icon {
          height: 30px;
          color: $background2;
          margin-left: 20px;
        }
      }
    }
    .fileSearch {
      width: 300px;
      margin: 30px 10px;
      display: flex;
      align-items: center;
      .svg-icon {
        padding: 0 20px;
      }
    }
  }

  .shipmentHeader {
    height: 30px;
  }

  .shipmentView {
    .topData {
      display: flex;
      border-bottom: 2px solid $mainColor;
      padding: 20px 0;
      .topDataLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .topDataTitle {
          font-size: 60px;
          color: $borderColor1;
          text-align: left;
        }
      }
    }
    .mainData {
      display: flex;
      font-size: 20px;
      line-height: 30px;
      .mainData-title {
        color: $fontColor3;
        width: 180px;
        text-align: right;
        margin-right: 10px;
      }
      .mainData-value {
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 315px;
        text-align: left;
      }
    }
  }
}
</style>