<template>
  <div class="eDoc">
    <div class="fileItem" v-for="item in edocList" :key="item.id">
      <div class="fileItemLeft">
        <div class="fileIcon">
          <svg-icon :icon-class="item.fileType"></svg-icon>
        </div>
        <div class="fileMain">
          <div class="invoice">
            {{ item.invoice }}
          </div>
          <div class="fileName">
            {{ item.fileName }}
          </div>
          <div class="lastUpdate">{{$t('lang.BText38')}}</div>
          <div class="lastUpdateDate">
            {{ item.lastUpdateDate }}
          </div>
        </div>
      </div>

      <div class="Download">
        <el-button type="info" @click="Download(item.filePath)">{{$t('lang.NText140')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      edocList: [],
    }
  },
  computed: {
    // edocList: function () {
    //   this.$store.state.api.shipmentData.edocMap.edocList.forEach((element) => {
    //     let fileType = element.fileName.split(".")[1];
    //     element.fileType = fileType;
    //   });
    //   return this.$store.state.api.shipmentData.edocMap.edocList;
    // },
  },
  props : {
    shipmentData: {
      type: Object,
      default: () => {}
    },
  },
  created(){
    this.edocList = this.edocListFun(this.shipmentData)
  },
  methods:{
      Download(file){
          window.open(file)
      },
      edocListFun (arr) {
        arr.edocMap.edocList.forEach((element) => {
          let fileType = element.fileName.split(".")[1];
          element.fileType = fileType;
        });
        return arr.edocMap.edocList;
      },
  }
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.eDoc {
  .fileItem {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 2px solid $mainColor;
    .fileItemLeft{
        display: flex;
    }
    .fileIcon {
      height: 120px;
      width: 93px;
      .svg-icon {
        height: 120px;
        width: 93px;
      }
    }
    .fileMain {
        text-align: left;
        margin-left: 50px;
      .invoice {
        font-size: 30px;
        color: $mainColor;
      }
      .fileName {
        font-size: 24px;
        color: $background2;
        margin: 12px 0;
      }
      .lastUpdate {
        font-size: 14px;
        color: $fontColor3;
      }
      .lastUpdateDate {
        font-size: 20px;
        color: $mainColor;
      }
    }
    .Download{
        margin: auto 0;
    }
  }
}
</style>