<template>
  <div class="routing">
    <div class="fromTo">
      <div class="left">
        <div class="fromTo-title">{{$t('lang.NText135')}}</div>
        <div
          class="fromTo-address"
          :title="TrackClickArr[0].currentNewStatus"
          v-if="TrackClickArr[0].currentNewStatus"
        >
          {{ TrackClickArr[0].currentNewStatus }}
        </div>
        <div class="fromTo-date" v-if="TrackClickArr[0].currentTime">
          {{ TrackClickArr[0].currentTime.split(' ')[0] }}
        </div>
      </div>
      <div class="typeIcon">
        <svg-icon
          class="listTypeIcon"
          icon-class="oceanFreight"
          v-if="shipmentData.routingMap.scheduleList[0].transportMode == 'Sea'"
        ></svg-icon>
        <svg-icon
          class="listTypeIcon"
          icon-class="trucks"
          v-if="shipmentData.routingMap.scheduleList[0].transportMode == 'Road'"
        ></svg-icon>
        <svg-icon
          class="listTypeIcon"
          :icon-class="train"
          v-if="shipmentData.routingMap.scheduleList[0].transportMode == 'Rail'"
        ></svg-icon>
        <svg-icon
          class="listTypeIcon"
          :icon-class="train"
          v-if="shipmentData.routingMap.scheduleList[0].transportMode == 'Air'"
        ></svg-icon>
      </div>
      <div class="right">
        <div class="fromTo-title">{{$t('lang.QText19')}}</div>
        <div
          class="fromTo-address"
          :title="TrackClickArr[0].currentNewStatus_next"
          v-if="TrackClickArr[0].currentNewStatus_next"
        >
          {{ TrackClickArr[0].currentNewStatus_next }},
          <!-- <span style="color: #299be4">
            {{ shipmentData.routingMap.scheduleList[1].loadPortCountry }}
          </span> -->
        </div>
        <div class="fromTo-date" v-if="TrackClickArr[0].currentTime_next">
          {{ TrackClickArr[0].currentTime_next.split('T')[0] }}
          <!-- <span style="color: #299be4">
            {{ shipmentData.routingMap.scheduleList[1].dischargePortType }}
          </span> -->
        </div>
      </div>
    </div>
    <div class="RoutingSchedule">
      <div class="RoutingScheduleTitle">{{$t('lang.NText148')}}</div>
      <div class="RoutingScheduleList">
        <dwlist
          source="local"
          :defaultData="shipmentData.routingMap.scheduleList"
          :initDataFlag="false"
          :pagination="false"
        >
          <el-table-column prop="type" label="" width="80px">
            <template slot-scope="scope">
              <svg-icon
                class="listTypeIcon"
                icon-class="oceanFreight"
                v-if="scope.row.transportMode == 'Sea'"
              ></svg-icon>
              <svg-icon
                class="listTypeIcon"
                icon-class="trucks"
                v-if="scope.row.transportMode == 'Road'"
              ></svg-icon>
              <svg-icon
                class="listTypeIcon"
                icon-class="train"
                v-if="scope.row.transportMode == 'Rail'"
              ></svg-icon>
              <svg-icon
                class="listTypeIcon"
                icon-class="airFreight"
                v-if="scope.row.transportMode == 'Air'"
              ></svg-icon>
            </template>
          </el-table-column>
          <el-table-column prop="summry" :label="$t('lang.NText149')">
            <template slot-scope="scope">
              <div class="port">{{ scope.row.carrier }}</div>
              <div class="port">
                {{ scope.row.vessel }} {{ scope.row.voyage }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CreateDate" :label="$t('lang.NText150')">
            <template slot-scope="scope">
              <div class="port">
                {{ scope.row.loadPort }},{{ scope.row.loadPortCountry }}
              </div>
              <div class="date">
                {{ scope.row.loadPortDate.split(' ')[0] }}
              </div>
              <div class="date">
                <span
                  style="color: #eb4b4b"
                  v-show="scope.row.lastloadType == 1"
                >
                  {{ scope.row.loadDayStr }}
                </span>
                <span style="color: #299be4">{{ scope.row.loadPortType }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="CargoReadyDay"
            :label="$t('lang.BText27')"
            width="200"
          >
            <template slot-scope="scope">
              <div class="port">
                {{ scope.row.dischargePort }},{{ scope.row.loadPortCountry }}
              </div>
              <div class="date">
                {{ scope.row.dischargePortDate.split(' ')[0] }}
              </div>
              <div class="date">
                <span
                  style="color: #eb4b4b"
                  v-show="scope.row.lastdisType == 1"
                >
                  {{ scope.row.disDayStr }}
                </span>
                <span style="color: #299be4">
                  {{ scope.row.dischargePortType }}
                </span>
              </div>
            </template>
          </el-table-column>
        </dwlist>
      </div>
    </div>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
export default {
  components: {
    dwlist,
  },
  computed: {
    // shipmentData: function () {
    //   console.log()
    //   return this.$store.state.api.shipmentData;
    // },
  },
  data() {
    return {
      spanArr: [],
      TrackClickArr: [],
    };
  },
  props : {
    shipmentData: {
      type: Object,
      default: () => {}
    },
  },
  created() {
    let arr = [{
        currentNewStatus: '',
        currentTime: '',
        currentNewStatus_next: '',
        currentTime_next: '',
    }];
    this.getSpanArr();
    this.TrackClickArr = JSON.parse(sessionStorage.getItem('TrackClickArr'))?JSON.parse(sessionStorage.getItem('TrackClickArr')):arr;
  },
  methods: {
    getSpanArr() {
      // data就是我们从后台拿到的数据
      for (var i = 0; i < this.shipmentData.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (
            this.shipmentData[i].transportMode ===
            this.shipmentData[i - 1].transportMode
          ) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
        console.log(this.spanArr);
      }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(columnIndex);
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        console.log(`rowspan:${_row} colspan:${_col}`);
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.routing {
  text-align: left;
  .fromTo {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    .left,
    .right {
      .fromTo-title {
        color: $borderColor1;
        font-size: 20px;
      }
      .fromTo-address {
        font-size: 30px;
        color: $mainColor;
        margin: 30px 0 10px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 367px;
      }
      .fromTo-date {
        font-size: 20px;
        color: $mainColor;
      }
    }
    .svg-icon {
      height: 120px;
      width: 120px;
    }
    .right {
      text-align: right;
      .fromTo-date {
        font-size: 20px;
        color: $fontColor3;
      }
    }
  }
  .RoutingSchedule {
    .RoutingScheduleTitle {
      font-size: 30px;
      color: $mainColor;
      margin-top: 40px;
      padding-bottom: 10px;
      border-bottom: 2px solid $mainColor;
      margin-bottom: 10px;
    }
  }
  .listTypeIcon {
    height: 40px;
    width: 40px;
  }
  .typeIcon {
    color: $mainColor;
  }
  .port {
    font-size: 20px !important;
  }
  .date {
    font-size: 14px !important;
  }
}
</style>