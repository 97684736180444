<template>
  <div class="box-card dwScrollbar">
    <k-second-header
      :clickchange="false"
      :data="settingList"
      @handleSelect="handleSelect"
      :activeIndex="tab"
    ></k-second-header>
    <div class="headeBbutton">
      <div class="refresh" @click="query">
        <svg-icon icon-class="refresh"></svg-icon>
        {{ $t("lang.BText60") }}
      </div>
      <div class="refresh" @click="Manage">
        <svg-icon icon-class="setting"></svg-icon>
        {{ $t("lang.BText61") }}
      </div>
    </div>
    <template v-if="tab == 0">
      <dwlist
        :key="Math.ceil(Math.random() * 100000)"
        :url="source.booking_api.bookingList"
        :query-params="bookingQueryForm"
        :showHeader="false"
        cellClassName="fillRow"
        ref="bookingList"
        @row-click="getABooking"
      >
        <el-table-column prop="summry" :label="$t('lang.BText4')">
          <template slot-scope="scope">
            <div
              class="type"
              v-if="quotationTypeObj[scope.row.bookingType] == 'FCL'"
            >
              {{ $t("lang.QText1") }}-{{
                quotationTypeObj[scope.row.bookingType]
              }}
            </div>
            <div
              class="type"
              v-if="quotationTypeObj[scope.row.bookingType] == 'LCL'"
            >
              {{ $t("lang.QText1") }}-{{
                quotationTypeObj[scope.row.bookingType]
              }}
            </div>
            <div class="port">
              {{ scope.row.originPortName
              }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
              >{{ scope.row.destinationDischargePortName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreateDate" :label="$t('lang.BText5')">
          <template slot-scope="scope">
            <div class="CreateDate">
              {{ $t("lang.BText62") }}：{{ scope.row.createDate }}
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </template>
    <template v-if="tab == 1">
      <div class="templateList">
        <dwlist
          :key="Math.ceil(Math.random() * 100000)"
          :url="source.booking_api.draftList"
          :query-params="draftQueryForm"
          :showHeader="false"
          cellClassName="fillRow"
          ref="draftTList"
          @row-click="getABooking2"
          :pagination="false"
        >
          <el-table-column width="20">
            <template slot-scope="scope">
              <div class="line1">{{ scope.$index + 1 }}.</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quotationTemplateName"
            :label="$t('lang.BText4')"
          >
            <template slot-scope="scope">
              <div class="line2">
                <div class="type" v-if="scope.row.bookingType == 'FCL'">
                  {{ $t("lang.QText1") }}-{{ scope.row.bookingType }}
                </div>
                <div class="type" v-if="scope.row.bookingType == 'LCL'">
                  {{ $t("lang.QText1") }}-{{ scope.row.bookingType }}
                </div>
                <span v-if="scope.row.originPortName !== ''">{{
                  scope.row.originPortName
                }}</span>
                --
                <span v-if="scope.row.destinationDischargePortName !== ''">{{
                  scope.row.destinationDischargePortName
                }}</span>
                <span class="line3" v-if="scope.row.containerType !== ''">
                  <span
                    v-for="(item, index) in JSON.parse(scope.row.containerType)"
                    :key="index"
                  >
                    {{ item.containerTypOne }} * {{ item.containerTypTwo }}
                  </span>
                </span>
              </div>
              <div class="line2" style="text-align: right">
                {{ $t("lang.BText105") }} : {{ scope.row.updateDate }}
              </div>
            </template>
          </el-table-column>
        </dwlist>
      </div>
    </template>
    <template v-if="tab == 2">
      <div class="templateList">
        <dwlist
          :key="Math.ceil(Math.random() * 100000)"
          :url="source.booking_api.templateList"
          :query-params="temQueryForm"
          :showHeader="false"
          cellClassName="fillRow"
          ref="bTList"
          @row-click="getABooking2"
          :pagination="false"
        >
          <el-table-column
            prop="quotationTemplateName"
            :label="$t('lang.BText4')"
          >
            <template slot-scope="scope">
              <div class="line1">
                {{ scope.$index + 1 }}. {{ scope.row.templateName }}
              </div>
              <div class="line2">
                {{ quotationTypeObj[scope.row.airSea] }} -
                {{ quotationTypeObj[scope.row.quotationType] }} |
                <span v-if="scope.row.originPortName !== ''">{{
                  scope.row.originPortName
                }}</span>
                <span> - </span>
                <span v-if="scope.row.destinationDischargePortName !== ''">{{
                  scope.row.destinationDischargePortName
                }}</span>
                |
                <span v-if="scope.row.shipper !== ''">{{
                  scope.row.shipper
                }}</span>
              </div>
            </template>
          </el-table-column>
        </dwlist>
      </div>
    </template>
    <template v-if="tab == 3">
      <dwlist
        :key="Math.ceil(Math.random() * 100000)"
        :url="source.quotation_api.quotationListData"
        :query-params="quoQueryForm"
        :showHeader="false"
        cellClassName="fillRow"
        ref="quoList"
        @row-click="getAQuote"
      >
        <el-table-column prop="summry" :label="$t('lang.BText4')">
          <template slot-scope="scope">
            <div class="type">
              {{ quotationTypeObj[scope.row.quotationMainType] }} -
              {{ quotationTypeObj[scope.row.quotationType] }}
            </div>
            <div class="summry">
              <span v-if="scope.row.fromType == 0">{{
                scope.row.fromCyPortName
              }}</span>
              <span v-if="scope.row.fromType == 1"
                >{{ scope.row.fromDoorCityName }},{{
                  scope.row.fromDoorCountry
                }}</span
              >
              <span style="color: #299be4"> -> </span>
              <span v-if="scope.row.toType == 0">{{
                scope.row.toCyPortName
              }}</span>
              <span v-if="scope.row.toType == 1"
                >{{ scope.row.toDoorCityName }},{{
                  scope.row.toDoorCountry
                }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreateDate" :label="$t('lang.BText5')">
          <template slot-scope="scope">
            <div class="CreateDate">
              {{ $t("lang.BText62") }}：{{ scope.row.createDate }}
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </template>
  </div>
</template>

<script>
import kSecondHeader from "../../components/kHeader/kSecondHeader.vue";
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import Template from "./template.vue";
import utils from "../../utils/util";
export default {
  components: {
    kSecondHeader,
    dwlist,
    Template,
  },
  props: {
    defaultTab: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      settingList: [
        {
          menuName: "Recent booking",
          extend: {
            extendS3: "最近订舱",
          },
        },
        {
          menuName: "Draft",
          extend: {
            extendS3: "草稿",
          },
        },
        {
          menuName: "Template",
          extend: {
            extendS3: "模板",
          },
        },
        {
          menuName: "Quotation",
          extend: {
            extendS3: "报价",
          },
        },
      ],
      listData: test.listData,
      templateData: test.templateData,
      tab: null,
      quoQueryForm: {
        state: 20,
      },
      quotationTypeObj: {},
      temQueryForm: {},
      bookingQueryForm: {},
      draftQueryForm: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleSelect(tab) {
      this.tab = tab;
    },
    query() {
      if (this.tab == "0") {
        this.$refs.bookingList.query();
      }
      if (this.tab == "1") {
        this.$refs.draftTList.query();
      }
      if (this.tab == "2") {
        this.$refs.bTList.query();
      }
      if (this.tab == "3") {
        this.$refs.quoTList.query();
      }
    },
    init() {
      this.getBaseData();
      this.tab = this.defaultTab;
    },
    getBaseData() {
      this.$session("userInfo").get((value) => {
        this.quoQueryForm.companyId = value.companyid;
        this.bookingQueryForm.companyId = value.companyid;
        this.draftQueryForm.companyId = value.companyid;
      });
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
    },
    // 获取服务数据
    setServices(e) {},
    getAQuote(row, column, event) {
      var form = {
        dataSource: "2",
        airSea: row.quotationMainType,
        bookingType: row.quotationType,
        originPort: row.crmFromPort,
        originPortName: row.crmFromPortName,
        originAddress: row.fromDoorAddress,
        originCountryRegion: row.fromDoorCountry,
        originCity: row.fromDoorCity,
        originState: row.fromDoorState,
        originZipCode: row.fromDoorZipCode,
        destinationDischargePort: row.crmToPort,
        destinationDischargePortName: row.crmToPortName,
        destinationAddress: row.toDoorAddress,
        destinationCountryRegion: row.toDoorCountry,
        destinationCity: row.toDoorCity,
        destinationState: row.toDoorState,
        destinationZipCode: row.toDoorZipCode,
        originCountryRegionName: row.fromDoorCountryName,
        originCityName: row.fromDoorCityName,
        originStateName: row.fromDoorStateName,
        destinationCountryRegionName: row.toDoorCountryName,
        destinationCityName: row.toDoorCityName,
        destinationStateName: row.toDoorStateName,
        cargoReadyDay: row.cargoReadyDate,
        destinationTargetDeliveryDate: row.deliveryDate,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantityUnit: row.totalQuantitUnit,
        incotermOne: row.incotermsType,
        incotermTwo: row.incotermsDataType,
        cargoType: row.cargoType,
        cargoDetails: "1",
        commodityHscodes: row.commodityHscodes,
        notificationRemark: row.masks,
        quotationNo: row.quotationNo,
        quotationId: row.id,
        cargos: [],
        cargoTypeName: row.cargoTypeName,
      };
      // if (this.$i18n.locale == "en_us") {
      //   form.destinationTargetDeliveryDate = utils.enDate_to_zhDate(
      //     form.destinationTargetDeliveryDate
      //   );
      //   form.cargoReadyDay = utils.enDate_to_zhDate(form.cargoReadyDay);
      // }
      form.containerType = [];
      if (row.containerDatas !== "" && row.containerDatas) {
        let bb = JSON.parse(row.containerDatas);
        bb.forEach((item, index) => {
          let cc = {
            containerTypOne: item.containerSize,
            containerTypTwo: item.count,
          };
          form.containerType.push(cc);
        });
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        let aa = JSON.parse(row.dangerousGoodsType);
        form.totalBatteries = aa.indexOf("0") == -1 ? "0" : "1";
        form.hazardousMaterial = aa.indexOf("1") == -1 ? "0" : "1";
        form.totalOther = aa.indexOf("2") == -1 ? "0" : "1";
        form.lithiumBattery = aa.indexOf("3") == -1 ? "0" : "1";
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPickUp = "1";
              break;
            case "1":
              form.originCustomerClearance = "1";
              break;
            case "2":
              form.originInsurance = "1";
              form.originValue = item.insurVlaue;
              form.originCurrency = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWarehouse = "1";
              form.originValueAddedSevice = item.warehouse;
              break;
            case "6":
              form.destinationDelivery = "1";

              break;
            case "7":
              form.customerClearance = "1";
              break;
            case "8":
              form.destinationBound = "1";
              item.bondType == 0
                ? (form.destinationBoundValue = "Single bond")
                : (form.destinationBoundValue = "Annual bond");
              break;
            case "9":
              form.destinationWarehouse = "1";
              form.destinationWarehouseValue = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocumentation = "1";
              break;

            default:
              break;
          }
        });
      }
      this.$emit("fillInBooking", { bookingData: form, fillIn: true });
    },
    getABooking(row, column, event) {
      this.$emit("fillInBooking", { bookingId: row.id, fillIn: true });
    },
    getABooking1(row, column, event) {
      this.$emit("fillInBooking", { draftId: row.id, fillIn: true });
    },
    getABooking2(row, column, event) {
      this.$emit("fillInBooking", { dOrTId: row.id, fillIn: true });
    },
    Manage() {
      this.$emit("Manage", this.tab);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.box-card {
  width: 700px;
  min-height: 800px;
  overflow: auto !important;
  border-radius: 0 !important;
  padding: 0 2px;
  margin: auto;

  .el-table {
    border: 0;
  }
  .el-table--fit {
    border-right: 0 !important;
  }
  .el-table::before {
    height: 0;
  }
  .summry {
    font-size: 18px;
  }
  .CreateDate,
  .type {
    font-size: 14px;
    color: $fontColor2;
    line-height: 14px;
  }
  .fillRow {
    border-bottom: 0 !important;
    padding: 5px 0 !important;
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    .el-pagination__total {
      position: absolute;
      left: 20px;
    }
  }
  .kSecondHeader {
    margin-bottom: 15px;
  }
  .headeBbutton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .refresh {
      margin-right: 30px;
      line-height: 20px;
      color: $mainColor;
      font-size: 14px;
      .svg-icon {
        color: $mainColor;
        height: 20px;
        width: 20px;
        margin-right: 10px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
  }
  .templateList {
    padding-top: 20px;
    .item {
      padding: 10px 20px;
    }
    .item:hover {
      background-color: $background1;
    }
    .line1 {
      color: $background2;
      font-size: 18px;
    }
    .line2 {
      font-size: 14px;
      color: $fontColor2;
      padding-left: 20px;
    }
  }
  .port {
    .svg-icon {
      color: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>